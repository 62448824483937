import {Select, SelectProps} from "@mantine/core";
import {EQuoteStatus, QuoteStatusMap} from "features/quotes";

export function QuoteStatusSelect(props: Omit<SelectProps, 'data'>) {

    const data = Object.keys(QuoteStatusMap).map(key => (
        {value: key, label: (QuoteStatusMap)[key as EQuoteStatus].text}
    ))

    return (
        <Select {...props} data={data}/>
    )
}