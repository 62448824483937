import {ModelHooks} from "services/strapi";
import { supplierApi } from "../services/supplier.strapi.api";
import { ISupplier } from "../types/supplier.types";

class SupplierHooks extends ModelHooks<ISupplier> {
    /** Add more functions or properties here that are not described in the Base Strapi
     *  Model Hooks Service.
     **/

}

export const supplierHooks = new SupplierHooks(supplierApi);
