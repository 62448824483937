import {ExternalIdDisplay, ListItemsTableSettings} from "components/common";
import {ActionIcon, Anchor, Code, CopyButton, Group, Text, TextInput, Tooltip} from "@mantine/core";
import {RoutesResolver} from "routes";
import {IProject} from "features/projects";
import {Link} from "react-router-dom";
import React from "react";
import {Check, Copy} from "tabler-icons-react";

interface TableSettingProps {
    routesResolver: RoutesResolver,
}

export type TTableSettings =
    (props: TableSettingProps) =>
        ListItemsTableSettings<IProject & { id: number }, void>[];

export const TableSettings: TTableSettings = (
    {
        routesResolver,
    }: TableSettingProps) => [
    {
        key: 'name',
        label: "Name",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {name} = data;
                return (
                    <Anchor target="_blank" component={Link} to={routesResolver.getProjectById(data.id)} size="sm">
                        {name}
                    </Anchor>
                )
            },
        }
    },
    {
        key: 'externalId',
        label: "Project ID",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {externalId} = data;
                return (
                    <ExternalIdDisplay externalId={externalId}/>
                )
            },
        }
    },
    {
        key: 'account',
        label: "Account",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {account} = data;
                if (account?.data) {
                    return (
                        <Anchor component={Link} to={routesResolver.getAccountById(account.data.id)}>
                            {account.data.attributes.name}
                        </Anchor>
                    )
                } else {
                    return (
                        <Text size="sm">
                            {account?.data?.attributes.name}
                        </Text>
                    )
                }
            },
        }
    },
]