import {Stack, StackProps} from "@mantine/core";
import {CONTENT_ALIGN, DEFAULT_MAX_CONTENT_WIDTH} from "settings";

interface Props extends StackProps {
    children:React.ReactNode,
    maxWidth?:number,
}

export function DefaultPageWrapper({children, maxWidth, ...rest}:Props){
    return (
        <Stack p={24} align={CONTENT_ALIGN} {...rest}>
            <Stack sx={{maxWidth: maxWidth || DEFAULT_MAX_CONTENT_WIDTH, width: "100%"}} spacing={0}>
                {children}
            </Stack>
        </Stack>
    )
}
