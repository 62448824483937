import {Select, SelectProps} from "@mantine/core";
import {projectHooks} from "features/projects";

interface Props extends Omit<SelectProps, 'data'> {
    filters: any,
    account: number,
}

export function ProjectsSelect({filters, account, ...rest}: Props) {
    const {
        isLoading,
        data: projects
    } = projectHooks.useGet({
        filters: {
            ...filters,
            account
        }
    })
    const {mutateAsync} = projectHooks.useCreate()

    const projectsData = projects?.data ? projects.data?.map(project => ({
        value: project.id.toString(), label: project.attributes.name
    })) : []

    return (
        <Select
            searchable
            creatable
            getCreateLabel={(query) => `+ Create ${query}`}
            onCreate={(query) => {
                const item = {value: query, label: query};
                mutateAsync({name: query, account})
                return item;
            }}
            data={projectsData}
            {...rest}
            disabled={isLoading || !account}/>
    )
}