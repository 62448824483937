import {useState} from 'react';
import {Box, Button, Grid, Group} from '@mantine/core';
import {useForm} from '@mantine/form';
import {ArrowRight} from "tabler-icons-react";
import { IPart } from "features/parts"

interface Props {
    onSubmitQuery?: (query: any) => void,
    initialValues?: any
}

export function PartCreateForm({onSubmitQuery, initialValues}: Props) {
    const [creatingPart, setCreatingPart] = useState(false);
    const form = useForm<IPart>({
        initialValues: initialValues || {},
        validate: {},
    });


    async function onSubmit(values: typeof form.values) {
        if (onSubmitQuery) {
            setCreatingPart(true);
            await onSubmitQuery(values)
            setCreatingPart(false);
        }
    }


    return (
        <Box mx="auto">
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Grid>
                    <Grid.Col sm={12}>
                        <Group position="right" mt="md">
                            <Button type="submit" loading={ creatingPart }
                                    rightIcon={<ArrowRight/>}>
                                Add New Part
                            </Button>
                        </Group>
                    </Grid.Col>
                </Grid>
            </form>
        </Box>
    )
}
