import {
    purchaseOrderHooks,
    PurchaseOrderCreate,
    IPurchaseOrder, PurchaseOrdersListTable
} from "features/purchase-orders";

import {Content, Page} from "components/common";
import {DefaultPageWrapper} from "layouts";
import useChangeDocumentTitle from "hooks/use-change-document-title";

export function PurchaseOrders() {
    const {isLoading: isLoadingPurchaseOrders, data: purchaseOrders} = purchaseOrderHooks.useGet({
        populate: [
            'supplier',
            'quote'
        ]
    })
    useChangeDocumentTitle(`Purchase Orders`)
    const {mutateAsync} = purchaseOrderHooks.useCreate();

    async function onSubmitCreate(values: IPurchaseOrder) {
        return await mutateAsync(values);
    }

    return (
        <DefaultPageWrapper>
            <Page.Header>
                <Page.Title title="Purchase Orders"/>
                {/*<PurchaseOrderCreate.Button onSubmitForm={onSubmitCreate}/>*/}
            </Page.Header>
            <Page.Body>
                <Content.Section>
                    <Content.Body>
                        <PurchaseOrdersListTable
                            loading={isLoadingPurchaseOrders}
                            data={purchaseOrders?.data}/>
                    </Content.Body>
                </Content.Section>
            </Page.Body>
        </DefaultPageWrapper>
    )
}
