import {ModelHooks} from "services/strapi";
import { purchaseOrderApi } from "../services/purchase-order.strapi.api";
import { IPurchaseOrder } from "../types/purchase-order.types";

class PurchaseOrderHooks extends ModelHooks<IPurchaseOrder> {
    /** Add more functions or properties here that are not described in the Base Strapi
     *  Model Hooks Service.
     **/

}

export const purchaseOrderHooks = new PurchaseOrderHooks(purchaseOrderApi);
