import {IStrapiEntity, IStrapiFlatEntity} from "services/strapi";

export class StrapiUtils {
    static flattenStrapiEntity<T>(entity: IStrapiEntity<T>): IStrapiFlatEntity<T> {
        return {...entity.attributes, id: entity.id}
    }

    static flattenStrapiEntities<T>(entities?: IStrapiEntity<T>[]): IStrapiFlatEntity<T>[] | undefined {
        if (entities) {
            return entities.map(entity => StrapiUtils.flattenStrapiEntity(entity))
        } else {
            return undefined
        }
    }
}