import {ModelRoutes, ModelApiService} from "services/strapi";
import { IInvoice } from "features/invoices";
import {strapi} from "lib/strapi-api";
import axios from "axios";

class InvoiceApiSingleton extends ModelApiService<IInvoice> {

}

export const invoiceApi = new InvoiceApiSingleton(ModelRoutes.Invoices)


