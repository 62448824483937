import {ModelHooks} from "services/strapi";
import {IUserDatum} from "../types/user-data.types";
import {userDatumApi} from "../services/user-data.strapi.api";

class UserDatumApiHooks extends ModelHooks<IUserDatum> {
    /** Add more functions or properties here that are not described in the Base Strapi
     *  Model Hooks Service.
     **/
}

export const userDatumHooks = new UserDatumApiHooks(userDatumApi);
