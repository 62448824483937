import {ModelHooks} from "services/strapi";
import { accountApi } from "../services/account.strapi.api";
import { IAccount } from "../types/account.types";

class AccountHooks extends ModelHooks<IAccount> {
    /** Add more functions or properties here that are not described in the Base Strapi
     *  Model Hooks Service.
     **/

}

export const accountHooks = new AccountHooks(accountApi);
