export class BaseRoutesResolver {
    baseRoute;

    constructor(baseRoute:string) {
        this.baseRoute = baseRoute;
    }

    concatRoutes(routes: (string | number)[], prependSlash = false) {
        let str = routes.join('/');
        if (prependSlash) str = `/${str}`;
        return str;
    }

    concatRoutesToBase(routes: (string | number)[]) {
        return this.concatRoutes([this.baseRoute, ...routes], false);
    }
}
