import {LoaderDefault, NoData} from "components/common";
import {Stack, Group, Paper, Divider, Pagination, Title} from "@mantine/core";
import {useState} from "react";
import {
    userHooks,
    UserFilters,
    IUser,
    UsersTable,
    UserInvite,
    userFiltersSettings
} from "features/users";
import {RolesUtils} from "utils";
import {useQueryManager} from "hooks";
import {DefaultPageWrapper} from "layouts";
import {IUserDatum} from "features/user-data";
import {authHooks} from "features/auth";
import useChangeDocumentTitle from "hooks/use-change-document-title";

export function Users() {
    const {
        query,
        onFilter
    } = useQueryManager(userFiltersSettings);
    const [page, setPage] = useState(1);
    const {data:user} = authHooks.useMe();
    const [sort, setSort] = useState({});
    useChangeDocumentTitle(`Users`)
    //eslint-disable-next-line
    const {isLoading: isLoadingRoles, data: roles} = userHooks.useGetInvitationRolePermissions(user!.role.name);

    const userQuery = {
        filters: {
            ...query,
        },
        pagination: {
            page: 1,
            pageSize: 25
        },
        sort: sort ? sort : {createdAt: 'asc'}
    }

    const {
        isLoading,
        data: users
    } = userHooks.useUsers(userQuery);
    const {mutateAsync} = userHooks.useMutateInviteUser();

    function onSort(field: keyof IUser | keyof IUserDatum, reversed: boolean) {
        const direction = reversed ? 'desc' : 'asc';
        setSort({[field]: direction});
    }

    async function onSubmitUserInviteQuery(values: any) {
        const curValues = {...values}
        return await mutateAsync(curValues);
    }

    function renderList() {
        if (isLoading || isLoadingRoles) {
            return <LoaderDefault text="Loading Users"/>
        } else if (!users || users.meta.pagination.total === 0) {
            return (
                <NoData
                    title="No Users"
                    description="You currently have no Users created."
                    action={RolesUtils.isAppAdmin(user) ?
                        <UserInvite.Button
                            onSubmitQuery={onSubmitUserInviteQuery}
                            roles={roles || []}/> : undefined}
                />
            )

        } else {
            return (
                <>
                    <UsersTable currentUser={user} userData={users?.data || []} onSort={onSort}/>
                    <Divider variant="dotted"/>
                    <Pagination
                        p={12}
                        size="sm"
                        page={page}
                        onChange={setPage}
                        total={users.meta.pagination.pageCount}/>
                </>
            )
        }
    }

    return (
        <DefaultPageWrapper>
            <Group mb={24} position="apart">
                <Title order={2}>
                    Users
                </Title>
                <Group>
                    {RolesUtils.isAppAdmin(user) &&
                        <UserInvite.Button
                            onSubmitQuery={onSubmitUserInviteQuery}
                            roles={roles || []}/>}
                </Group>
            </Group>
            <Paper>
                <Stack p={16}>
                    <UserFilters onSubmitQuery={onFilter}/>
                </Stack>
                {renderList()}
            </Paper>
        </DefaultPageWrapper>
    )
}
