import {
    createBrowserRouter,
    RouterProvider,
    createRoutesFromElements,
    Route,
    Outlet,
    Navigate,
} from "react-router-dom";
import {LoaderDefault} from "components/common";
import {AppWrapper, Auth, AppHeader} from "layouts";
import {
    AuthLogin,
    UserAcceptInvitation, ForgotPassword,
    Users, ResetPassword,
    /* Generated Feature Pages */
    QuoteVersion,
    QuoteVersions,
    Supplier,
    Suppliers,
    Shipment,
    Shipments,
    Quote,
    Quotes,
    PurchaseOrder,
    PurchaseOrders,
    Project,
    Projects,
    Part,
    Parts,
    LineItem,
    LineItems,
    Invoice,
    Invoices,
    FreightCarrier,
    FreightCarriers,
    Account,
    Accounts,
    QuoteOverview, QuoteFiles, QuotePurchaseOrders, QuoteInvoices, QuoteShipments,
} from "pages";
import {RouteSegments, RouteParams} from './segments';
import {authHooks} from 'features/auth'
import {QuoteQuoteVersions} from "pages/quote.versions";

export function AppRoutes() {

    const {isLoading, data: me} = authHooks.useMe();
    const {mutateAsync: login} = authHooks.useLogin();

    if (isLoading) {
        return <LoaderDefault text="Loading Application"/>
    }

    function renderAllRoutes() {
        if (me) {
            return (
                <Route element={<AppHeader/>} errorElement={<div>There's been an error!</div>}>
                    <Route element={<AppWrapper/>}>
                        <Route path={RouteSegments.Users} element={<Outlet/>}>
                            <Route index element={<Users/>}/>
                        </Route>
                        {/* Generated React Router Pages */}

                        <Route path={RouteSegments.QuoteVersions} element={<Outlet/>}>
                            <Route index element={<QuoteVersions/>}/>
                            <Route path={RouteParams.QuoteVersionId} element={<QuoteVersion/>}/>
                        </Route>


                        <Route path={RouteSegments.Suppliers} element={<Outlet/>}>
                            <Route index element={<Suppliers/>}/>
                            <Route path={RouteParams.SupplierId} element={<Supplier/>}/>
                        </Route>


                        <Route path={RouteSegments.Shipments} element={<Outlet/>}>
                            <Route index element={<Shipments/>}/>
                            <Route path={RouteParams.ShipmentId} element={<Shipment/>}/>
                        </Route>


                        <Route path={RouteSegments.Quotes} element={<Outlet/>}>
                            <Route index element={<Quotes/>}/>
                            <Route path={RouteParams.QuoteId} element={<Quote/>}>
                                <Route path={RouteSegments.QuoteVersions} element={<Outlet/>}>
                                    <Route index element={<QuoteQuoteVersions/>}/>
                                    <Route path={RouteParams.QuoteVersionId} element={<QuoteVersion/>}/>
                                </Route>
                                <Route path={RouteSegments.Settings} element={<QuoteOverview/>}/>
                                <Route path={RouteSegments.Files} element={<QuoteFiles/>}/>
                                <Route path={RouteSegments.PurchaseOrders} element={<QuotePurchaseOrders/>}/>
                                <Route path={RouteSegments.Invoices} element={<QuoteInvoices/>}/>
                                <Route path={RouteSegments.Shipments} element={<QuoteShipments/>}/>
                            </Route>
                        </Route>


                        <Route path={RouteSegments.PurchaseOrders} element={<Outlet/>}>
                            <Route index element={<PurchaseOrders/>}/>
                            <Route path={RouteParams.PurchaseOrderId} element={<PurchaseOrder/>}/>
                        </Route>


                        <Route path={RouteSegments.Projects} element={<Outlet/>}>
                            <Route index element={<Projects/>}/>
                            <Route path={RouteParams.ProjectId} element={<Project/>}/>
                        </Route>


                        <Route path={RouteSegments.Parts} element={<Outlet/>}>
                            <Route index element={<Parts/>}/>
                            <Route path={RouteParams.PartId} element={<Part/>}/>
                        </Route>


                        <Route path={RouteSegments.LineItems} element={<Outlet/>}>
                            <Route index element={<LineItems/>}/>
                            <Route path={RouteParams.LineItemId} element={<LineItem/>}/>
                        </Route>


                        <Route path={RouteSegments.Invoices} element={<Outlet/>}>
                            <Route index element={<Invoices/>}/>
                            <Route path={RouteParams.InvoiceId} element={<Invoice/>}/>
                        </Route>


                        <Route path={RouteSegments.FreightCarriers} element={<Outlet/>}>
                            <Route index element={<FreightCarriers/>}/>
                            <Route path={RouteParams.FreightCarrierId} element={<FreightCarrier/>}/>
                        </Route>


                        <Route path={RouteSegments.Accounts} element={<Outlet/>}>
                            <Route index element={<Accounts/>}/>
                            <Route path={RouteParams.AccountId} element={<Account/>}/>
                        </Route>


                        <Route index element={<Navigate to={RouteSegments.Users}/>}/>
                        <Route path={RouteSegments.Wildcard} element={<Navigate to={RouteSegments.Users}/>}/>
                    </Route>
                </Route>
            )
        } else {
            return (
                <Route errorElement={<div>There's been an error!</div>}>
                    <Route path={RouteSegments.Auth} element={<Auth/>}>
                        <Route path={RouteSegments.Login} element={<AuthLogin login={login}/>}/>
                        <Route path={RouteSegments.ForgotPassword} element={<ForgotPassword/>}/>
                        <Route path={RouteSegments.AcceptInvitation} element={<UserAcceptInvitation/>}/>
                        <Route path={RouteSegments.ResetPassword} element={<ResetPassword/>}/>
                    </Route>
                    <Route index element={<Navigate to={`/${RouteSegments.Auth}/${RouteSegments.Login}`}/>}/>
                    <Route path="*" element={<Navigate to={`/${RouteSegments.Auth}/${RouteSegments.Login}`}/>}/>
                </Route>
            )
        }
    }

    const router = createBrowserRouter(createRoutesFromElements(renderAllRoutes()))

    return <RouterProvider router={router}/>;
}

export default AppRoutes;
