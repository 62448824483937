import {Button, Text} from "@mantine/core";
import { ProjectCreateForm } from "./project.create.form";
import { useModals } from "@mantine/modals";
import { Plus } from "tabler-icons-react";

interface Props {
    onSubmitForm:(values:any) => void,
}

export function ProjectCreateButton({ onSubmitForm }:Props) {
    const modals = useModals();

    async function _onSubmitForm(values:any){
        await onSubmitForm(values);
        modals.closeAll();
    }

    const renderProjectForm = () => {
        modals.openModal({
            withCloseButton:true,
            closeOnClickOutside:true,
            closeOnEscape:true,
            size:400,
            overflow:'outside',
            title: (
                <Text size={'xl'}>Create New Project</Text>
            ),
            children: (
                <ProjectCreateForm onSubmitQuery={_onSubmitForm}/>
            ),
        })
        return false;
    }

    return (
        <Button leftIcon={<Plus size={18}/>} onClick={renderProjectForm}>
            New Project
        </Button>
    )
}
