import {Avatar, Group, Text} from "@mantine/core";
import {concatName, getInitials, IUser} from "features/users";
import React from "react";

interface Props {
    user?: IUser
    noLink?: boolean
}

export function UserAvatar({user, noLink}: Props) {

    function renderName() {
        if (noLink) {
            return <Text size="sm">{concatName(user)}</Text>
        } else {
            return null
        }
    }

    if (!user) {
        return <Text>None</Text>
    } else {
        return (
            <Group spacing={12}>
                {user.avatar ?
                <Avatar size="sm" radius="xl" src={user.avatar.data?.attributes.url}/> :
                <Avatar color="cyan">{getInitials(user)}</Avatar>}
                <Text size="sm">{concatName(user)}</Text>
            </Group>
        )
    }
}