import {createStyles, UnstyledButton, Group, Text, Center} from "@mantine/core";
import {Selector, ChevronDown, ChevronUp} from 'tabler-icons-react';

interface Props {
    children: React.ReactNode;
    reversed: boolean;
    sorted: boolean;

    onSort(): void;
}

const useStyles = createStyles((theme) => ({
    th: {
        padding: '0 !important',
    },

    control: {
        width: '100%',
        padding: `${theme.spacing.xs}px ${theme.spacing.xs}px`,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },

    icon: {
        width: 21,
        height: 21,
        borderRadius: 21,
    },
}));

export function TablesHeaderSortable({
    children,
    reversed,
    sorted,
    onSort
}: Props) {
    const {classes} = useStyles();
    const Icon = sorted ? (reversed ? ChevronUp : ChevronDown) : Selector;
    return (
        <th className={classes.th}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group position="apart" noWrap>
                    <Text weight={600} size="sm" sx={{
                        whiteSpace:"nowrap"
                    }}>
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon size={14}/>
                    </Center>
                </Group>
            </UnstyledButton>
        </th>
    );
}
