import {IQuote, QuoteCreate, quoteHooks} from "features/quotes";
import {DefaultPageWrapper} from "layouts";
import {useParams} from "react-router-dom";
import {LoaderDefault} from "components/common";
import useChangeDocumentTitle from "hooks/use-change-document-title";

export function QuoteOverview() {
    const {quoteId} = useParams();
    const {isLoading, data: quote} = quoteHooks.useGetById(parseInt(quoteId!), {
        populate: "*"
    });
    useChangeDocumentTitle(`Settings`)

    const {mutateAsync} = quoteHooks.useUpdate()

    if (isLoading) {
        return <LoaderDefault text="Loading quote"/>
    }

    async function onSubmitQuery(values: IQuote) {
        if (quote?.data) {
            await mutateAsync({
                id: quote?.data?.id,
                values: values,
            })
        }
    }

    return (
        <DefaultPageWrapper>
            <QuoteCreate.Component initialValues={quote?.data.attributes} onSubmitQuery={onSubmitQuery}/>
        </DefaultPageWrapper>
    )
}