import {Group, ScrollArea, useMantineTheme} from "@mantine/core";
import {Outlet} from "react-router-dom";
import {ThemeManager} from "utils";
import {getHeightForScroll} from "settings";
import {AppNavbar} from "./app.navbar";

export function AppWrapper() {
    const theme = useMantineTheme();

    return (
        <Group align="flex-start" p={0} style={{width: "100%"}} noWrap spacing={0}>
            <AppNavbar/>
            <ScrollArea sx={() => ({
                width: "100%",
                height: getHeightForScroll(),
                backgroundColor: ThemeManager.chooseColor(theme, false, theme.colors.gray[0], theme.colors.dark[8])
            })}>
                <Outlet/>
            </ScrollArea>
        </Group>
    )
}
