import {Stack, Title, Group, ActionIcon, Tooltip} from "@mantine/core";
import {Link, Outlet, useParams} from "react-router-dom";
import { shipmentHooks } from "features/shipments";
import {LoaderDefault, NoData} from "components/common";
import {DefaultPageWrapper} from "layouts";
import {ArrowLeft} from "tabler-icons-react";
import {RoutesResolver} from "routes";

export function Shipment() {
    const { shipmentId } = useParams();
    const {isLoading, data: shipment } = shipmentHooks.useGetById(parseInt(shipmentId!), {

    });

    function renderContent() {
        if (isLoading) {
            return <LoaderDefault text="Loading Shipment"/>
        } else if (!shipment) {
            return <NoData title="No Shipment with specified ID found"/>
        } else {
            return <Outlet/>
        }
    }

    const routesResolver = new RoutesResolver();

    return (
        <Stack spacing={0}>
            <DefaultPageWrapper>
                <Group spacing="sm">
                    <Tooltip label="Back to Shipments" withArrow>
                        <ActionIcon component={Link} size="sm" to={ routesResolver.shipments }>
                            <ArrowLeft size={18}/>
                        </ActionIcon>
                    </Tooltip>
                    <Title>Insert Shipment Title Attribute</Title>
                </Group>
            </DefaultPageWrapper>
            <Stack>
                {renderContent()}
            </Stack>
        </Stack>
    )

}
