import {
    lineItemHooks,
    LineItemCreate,
    ILineItem
} from "features/line-items";

import {LoaderDefault, NoData} from "components/common";
import {Group, Title} from "@mantine/core";
import { LineItemsTable } from "features/line-items";
import {DefaultPageWrapper} from "layouts";

export function LineItems() {
    const {isLoading, data: lineItems } = lineItemHooks.useGet({

    })
    const {mutateAsync} = lineItemHooks.useCreate();

    async function onSubmitCreate(values: ILineItem) {
        return await mutateAsync(values);
    }

    function renderContent() {
        if (isLoading) {
            return <LoaderDefault text="Loading Line-Item"/>
        } else if (!lineItems?.data || lineItems.meta.pagination.total === 0) {
            return <NoData title="No Line-Items"/>
        } else {
            return (
                <LineItemsTable lineItems={ lineItems.data }/>
            )
        }
    }

    return (
        <DefaultPageWrapper>
            <Group position="apart" mb={12}>
                <Title>Line-Item</Title>
                <LineItemCreate.Button onSubmitForm={onSubmitCreate}/>
            </Group>
            {renderContent()}
        </DefaultPageWrapper>
    )

}
