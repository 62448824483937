import {useState} from 'react';
import {Box, Button, Grid, Group, TextInput} from '@mantine/core';
import {useForm} from '@mantine/form';
import {ArrowRight} from "tabler-icons-react";
import {IFreightCarrier} from "features/freight-carriers"

interface Props {
    onSubmitQuery?: (query: any) => void,
    initialValues?: any
}

export function FreightCarrierCreateForm({onSubmitQuery, initialValues}: Props) {
    const [creatingFreightCarrier, setCreatingFreightCarrier] = useState(false);
    const form = useForm<IFreightCarrier>({
        initialValues: initialValues || {},
        validate: {},
    });


    async function onSubmit(values: typeof form.values) {
        if (onSubmitQuery) {
            setCreatingFreightCarrier(true);
            await onSubmitQuery(values)
            setCreatingFreightCarrier(false);
        }
    }


    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Grid>
                <Grid.Col sm={12}>
                    <TextInput label="Name" {...form.getInputProps('name')}/>
                </Grid.Col>
                <Grid.Col sm={12}>
                    <Group position="right" mt="md">
                        <Button type="submit" loading={creatingFreightCarrier}
                                rightIcon={<ArrowRight/>}>
                            Add New Freight Carrier
                        </Button>
                    </Group>
                </Grid.Col>
            </Grid>
        </form>
    )
}
