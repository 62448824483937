import {ModelHooks} from "services/strapi";
import { freightCarrierApi } from "../services/freight-carrier.strapi.api";
import { IFreightCarrier } from "../types/freight-carrier.types";

class FreightCarrierHooks extends ModelHooks<IFreightCarrier> {
    /** Add more functions or properties here that are not described in the Base Strapi
     *  Model Hooks Service.
     **/

}

export const freightCarrierHooks = new FreightCarrierHooks(freightCarrierApi);
