import {ModelRoutes, ModelApiService} from "services/strapi";
import { IQuote } from "features/quotes";
import {strapi} from "lib/strapi-api";
import axios from "axios";

class QuoteApiSingleton extends ModelApiService<IQuote> {

}

export const quoteApi = new QuoteApiSingleton(ModelRoutes.Quotes)


