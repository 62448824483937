import {ModelRoutes, ModelApiService} from "services/strapi";
import { IAccount } from "features/accounts";
import {strapi} from "lib/strapi-api";
import axios from "axios";

class AccountApiSingleton extends ModelApiService<IAccount> {

}

export const accountApi = new AccountApiSingleton(ModelRoutes.Accounts)


