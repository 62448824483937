import {
    Divider,
    Group,
    GroupProps,
    Paper,
    PaperProps,
    Stack,
    StackProps,
    Title as MantineTitle,
    TitleProps
} from "@mantine/core";

function Section({children, ...rest}: PaperProps) {
    return (
        <Paper {...rest}>
            {children}
        </Paper>
    )
}

function Header({children, ...rest}: GroupProps) {
    return (
        <Group py={12} px={16} position="apart" {...rest}>
            {children}
        </Group>
    )
}

function Body({children, withDivider, ...rest}: StackProps & { withDivider?:boolean }) {
    return (
        <>
            {withDivider && <Divider variant="dotted"/>}
            <Stack spacing={9} {...rest}>
                {children}
            </Stack>
        </>
    )
}

function Title({children, ...rest}: TitleProps) {
    return (
        <MantineTitle order={4} {...rest}>
            {children}
        </MantineTitle>
    )
}

export const Content = {
    Section, Header, Body, Title
}