import {useState} from 'react';
import {Button, Grid, Group, TextInput} from '@mantine/core';
import {notEmpty, useForm} from '@mantine/form';
import {ArrowRight} from "tabler-icons-react";
import {IProject} from "features/projects"
import {AccountsSelect} from "features/accounts";
import {generateExternalId} from "utils";
interface Props {
    onSubmitQuery?: (query: any) => void,
    initialValues?: any
}
export function ProjectCreateForm({onSubmitQuery, initialValues}: Props) {
    const [creatingProject, setCreatingProject] = useState(false);
    const form = useForm<IProject>({
        initialValues: initialValues || {
            externalId: generateExternalId(),
            name: "",
            account: ""
        },
        validate: {
            externalId: notEmpty(),
            name: notEmpty(),
            account: notEmpty()
        },
    });


    async function onSubmit(values: typeof form.values) {
        if (onSubmitQuery) {
            setCreatingProject(true);
            await onSubmitQuery(values)
            setCreatingProject(false);
        }
    }


    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Grid gutter={12}>
                <Grid.Col sm={12}>
                    <TextInput required label="Project ID" {...form.getInputProps('externalId')}/>
                </Grid.Col>
                <Grid.Col sm={12}>
                    <TextInput required label="Name" {...form.getInputProps('name')}/>
                </Grid.Col>
                <Grid.Col sm={12}>
                    <AccountsSelect required label="Account" {...form.getInputProps('account')}/>
                </Grid.Col>
                <Grid.Col sm={12}>
                    <Group position="right" mt="md">
                        <Button type="submit" loading={creatingProject}
                                disabled={!form.isValid()}
                                rightIcon={<ArrowRight/>}>
                            Add New Project
                        </Button>
                    </Group>
                </Grid.Col>
            </Grid>
        </form>
    )
}
