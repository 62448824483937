import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {MantineProvider, ColorSchemeProvider, ColorScheme} from '@mantine/core';
import {NotificationsProvider} from '@mantine/notifications';
import {AppRoutes} from "routes";
import {QueryClientProvider} from '@tanstack/react-query';
import {ModalsProvider} from '@mantine/modals';
import {queryClient} from './settings';
import {useColorScheme} from "@mantine/hooks";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

function Entrypoint() {
    const preferredColorScheme = useColorScheme();
    const localStorageScheme = localStorage.getItem("colorScheme")
    const [colorScheme, setColorScheme] = useState<ColorScheme>(localStorageScheme as ColorScheme || preferredColorScheme);
    const toggleColorScheme = (value?: ColorScheme) => {
        localStorage.setItem("colorScheme", value || (colorScheme === 'dark' ? 'light' : 'dark'))
        setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))
    };

    return (
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <MantineProvider withGlobalStyles theme={{
                colorScheme: colorScheme,
                    fontFamily: "Open Sans, sans-serif",
                    headings: {
                        fontFamily: "Inter, sans-serif"
                    },
                    primaryColor: "green",
                    components: {
                        Button: {
                            defaultProps: {
                                size: 'xs',
                            },
                        },
                        Paper:{
                          defaultProps: {
                              radius:"md",
                              withBorder: true,
                          }
                        },
                        Anchor: {
                            styles: (theme) => ({
                                root: {
                                    color: theme.colors.blue[6]
                                }
                            })
                        },
                        Avatar: {
                            defaultProps: {
                                size: "sm",
                                radius: "xl"
                            }
                        },
                        Table: {
                            styles: theme => ({
                                "th": {
                                    fontSize: "13px !important",
                                }
                            }),
                            defaultProps: {
                                fontSize: "sm",
                                verticalSpacing: "sm",
                            }
                        },
                        TextInput: {
                            defaultProps: {
                                size: "xs"
                            }
                        },
                        NumberInput: {
                            defaultProps: {
                                size: "xs"
                            }
                        },
                        Textarea: {
                            defaultProps: {
                                size: "xs"
                            }
                        },
                        DatePicker: {
                            defaultProps: {
                                size: "xs"
                            }
                        },
                        Select: {
                            defaultProps: {
                                size: "xs"
                            }
                        },
                    },
                    globalStyles: (theme) => ({
                        ".mantine-Modal-modal": {
                            padding: 0
                        },
                        th: {
                            fontSize: "13px !important",
                        },
                        ".mantine-Modal-header": {
                            padding: "12px 18px",
                            boxSizing: "border-box",
                            marginRight: 0,
                            marginBottom: 0,
                            borderBottom: `1px solid ${theme.colors.gray[3]}`,
                            ".mantine-Text-root": {
                                fontSize: 16,
                            }
                        },
                        ".mantine-Modal-body": {
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4,
                            padding: 16,
                            backgroundColor: theme.colors.gray[0]
                        },
                        body: {
                            margin: 0,
                            padding: 0,
                            fontWeight: 500,
                            WebkitFontSmoothing: "antialiased",
                            MozOsxFontSmoothing: "grayscale",
                            height: "100vh",
                        }
                    })
            }}>
                <NotificationsProvider>
                    <QueryClientProvider client={queryClient}>
                        <ModalsProvider>
                            <AppRoutes/>
                        </ModalsProvider>
                    </QueryClientProvider>
                </NotificationsProvider>
            </MantineProvider>
        </ColorSchemeProvider>
    )
}

root.render(<Entrypoint/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
