import {Title, Stack,} from '@mantine/core';
import {useSearchParams} from "react-router-dom";
import {authHooks, UserResetPasswordForm} from "features/auth";

export function ResetPassword() {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const {mutateAsync} = authHooks.useResetPassword();

    async function onResetPassword(password: string) {
        if (password && code) {
            return await mutateAsync({
                code,
                password,
                passwordConfirmation: password,
            })
        }
    }

    return (
        <Stack spacing={6}>
            <Title order={3} align="center">
                Reset your password
            </Title>
            <UserResetPasswordForm onResetPassword={onResetPassword}/>
        </Stack>
    );
}
