import {ListItemsTableSettings} from "components/common";
import {Text} from "@mantine/core";
import {RoutesResolver} from "routes";
import {IFreightCarrier} from "features/freight-carriers";

interface TableSettingProps {
    routesResolver: RoutesResolver,
}

export type TTableSettings =
    (props: TableSettingProps) =>
        ListItemsTableSettings<IFreightCarrier & { id: number }, void>[];

export const TableSettings: TTableSettings = (
    {
        routesResolver,
    }: TableSettingProps) => [
    {
        key: 'name',
        label: "Name",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {name} = data;
                return <Text>{name}</Text>
            },
        }
    },
]