import {MantineTheme} from "@mantine/core";

export class ThemeManager {
    static chooseColor(theme:MantineTheme, inverted = false, light?:string, dark?:string){
        let curScheme = theme.colorScheme;
        if(inverted){
            curScheme = curScheme === "dark" ? "light" : "dark"
        }
        if (curScheme === "dark"){
            return dark || theme.colors.dark[7];
        } else {
            return light || theme.white
        }
    }
}
