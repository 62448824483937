import {ModelRoutes, ModelApiService} from "services/strapi";
import { ILineItem } from "features/line-items";
import {strapi} from "lib/strapi-api";
import axios from "axios";

class LineItemApiSingleton extends ModelApiService<ILineItem> {

}

export const lineItemApi = new LineItemApiSingleton(ModelRoutes.LineItems, undefined, true)


