import {
    partHooks,
    PartCreate,
    IPart
} from "features/parts";

import {LoaderDefault, NoData} from "components/common";
import {Group, Title} from "@mantine/core";
import { PartsTable } from "features/parts";
import {DefaultPageWrapper} from "layouts";

export function Parts() {
    const {isLoading, data: parts } = partHooks.useGet({

    })
    const {mutateAsync} = partHooks.useCreate();

    async function onSubmitCreate(values: IPart) {
        return await mutateAsync(values);
    }

    function renderContent() {
        if (isLoading) {
            return <LoaderDefault text="Loading Part"/>
        } else if (!parts?.data || parts.meta.pagination.total === 0) {
            return <NoData title="No Parts"/>
        } else {
            return (
                <PartsTable parts={ parts.data }/>
            )
        }
    }

    return (
        <DefaultPageWrapper>
            <Group position="apart" mb={12}>
                <Title>Part</Title>
                <PartCreate.Button onSubmitForm={onSubmitCreate}/>
            </Group>
            {renderContent()}
        </DefaultPageWrapper>
    )

}
