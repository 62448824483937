import {Select, SelectProps} from "@mantine/core";
import {freightCarrierHooks} from "features/freight-carriers";

interface Props extends Omit<SelectProps, 'data'> {
    filters: any,
}

export function FreightCarriersSelect({filters, ...rest}: Props) {
    const {
        isLoading,
        data: freightCarriers
    } = freightCarrierHooks.useGet({
        filters: {
            ...filters,
        }
    })

    const freightCarriersData = freightCarriers?.data ? freightCarriers.data?.map(freightCarrier => ({
        value: freightCarrier.id.toString(), label: freightCarrier.attributes.name
    })) : []

    return (
        <Select
            searchable
            data={freightCarriersData}
            {...rest}
            disabled={isLoading}/>
    )
}