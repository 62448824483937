import {
    supplierHooks,
    SupplierCreate,
    ISupplier, SuppliersListTable
} from "features/suppliers";
import {Content, Page} from "components/common";
import {DefaultPageWrapper} from "layouts";
import useChangeDocumentTitle from "hooks/use-change-document-title";

export function Suppliers() {
    const {isLoading:isLoadingSuppliers, data: suppliers } = supplierHooks.useGet({

    })
    const {mutateAsync} = supplierHooks.useCreate();
    useChangeDocumentTitle(`Suppliers`)
    async function onSubmitCreate(values: ISupplier) {
        return await mutateAsync(values);
    }

    return (
        <DefaultPageWrapper>
            <Page.Header>
                <Page.Title title="Suppliers"/>
                <SupplierCreate.Button onSubmitForm={onSubmitCreate}/>
            </Page.Header>
            <Page.Body>
                <Content.Section>
                    <Content.Body>
                        <SuppliersListTable
                            data={suppliers?.data}
                            loading={isLoadingSuppliers}/>
                    </Content.Body>
                </Content.Section>
            </Page.Body>
        </DefaultPageWrapper>
    )

}
