import {
    projectHooks,
    ProjectCreate,
    IProject, ProjectsListTable
} from "features/projects";

import {Content, Page} from "components/common";
import {DefaultPageWrapper} from "layouts";
import {usePagination} from "@mantine/hooks";
import { useState } from "react";
import useChangeDocumentTitle from "hooks/use-change-document-title";

export function Projects() {
    const [page, setPage] = useState(1)
    useChangeDocumentTitle('Projects')
    const {isLoading: isLoadingProjects, data: projects} = projectHooks.useGet({
        populate: [
            'account'
        ],
        pagination: {
            pageSize: 10,
            page: page
        }
    })
    const pagination = usePagination({
        total: projects?.meta.pagination.pageCount,
        initialPage: 1,
        onChange: (page:number) => setPage(page)
    })
    const {mutateAsync} = projectHooks.useCreate();

    async function onSubmitCreate(values: IProject) {
        return await mutateAsync(values);
    }

    return (
        <DefaultPageWrapper>
            <Page.Header>
                <Page.Title title="Projects"/>
                <ProjectCreate.Button onSubmitForm={onSubmitCreate}/>
            </Page.Header>
            <Content.Section>
                <Content.Body>
                    <ProjectsListTable
                        pagination={{
                            page: pagination.active,
                            total: projects?.meta.pagination.pageCount,
                            onChange: pagination.setPage
                        }}
                        loading={isLoadingProjects}
                        data={projects?.data}/>
                </Content.Body>
            </Content.Section>
        </DefaultPageWrapper>
    )

}
