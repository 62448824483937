import {Container, Alert} from "@mantine/core";
import {useSearchParams} from "react-router-dom";
import {UserAcceptInvitationForm} from "features/users";
import {useEffect, useState} from "react";
import {FeedbackGeneral, LoaderDefault} from "components/common";
import {AlertCircle} from "tabler-icons-react";
import { userApi } from "features/users"


export function UserAcceptInvitation() {
    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState<string>();
    const [curInvitationToken, setCurInvitationToken] = useState<string>();
    const [emailLoaded, setEmailLoaded] = useState(false);
    const [invitationAccepted, setInvitationAccepted] = useState<boolean>();

    useEffect(() => {
        if (!email) {
            getEmailFromToken()
        }

        //eslint-disable-next-line
    }, [email])

    async function getEmailFromToken() {
        setEmailLoaded(false);
        const invitationToken = searchParams.get("invitationToken");
        if (invitationToken) {
            const email = await userApi.getEmailFromInvitationToken(invitationToken);
            if (email) {
                setEmail(email);
                setCurInvitationToken(invitationToken);
            }
        }
        setEmailLoaded(true);
    }

    async function _acceptInvitation(password: string) {
        if (curInvitationToken) {
            const user = await userApi.acceptInvitation(password, curInvitationToken);
            if (user) {
                setInvitationAccepted(true);
            }
        }
    }

    if (!emailLoaded) {
        return <LoaderDefault text="Loading Invitation"/>
    } else if (!email || invitationAccepted === false) {
        return <FeedbackGeneral title="Expired/Not Found"
                                label="Oops!"
                                urlRedirect="/"
                                description="Our apologies, your invitation has either expired or is no longer available."/>
    }

    if (invitationAccepted === true) {
        return <FeedbackGeneral title="Invitation Accepted"
                                urlRedirect="/"
                                description="You have successfully accepted your invitation, and you can now login to your account. Press the button below to navigate back to the login page."/>
    }

    return (
        <Container size={420} style={{width: "100%"}}>
            <Alert color="indigo" radius={0} icon={<AlertCircle size={16}/>} title="You've been invited!">
                To complete your registration to the M3 Quotes Dashboard, choose a password. Your email cannot be changed at this time.
            </Alert>

            <Container p={16}>
                <UserAcceptInvitationForm
                    email={email}
                    onAcceptInvitation={_acceptInvitation}/>
            </Container>
        </Container>
    )

}
