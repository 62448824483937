import {Button, Text} from "@mantine/core";
import { LineItemCreateForm } from "./line-item.create.form";
import { useModals } from "@mantine/modals";
import { Plus } from "tabler-icons-react";

interface Props {
    onSubmitForm:(values:any) => void,
}

export function LineItemCreateButton({ onSubmitForm }:Props) {
    const modals = useModals();

    async function _onSubmitForm(values:any){
        await onSubmitForm(values);
        modals.closeAll();
    }

    const renderLineItemForm = () => {
        modals.openModal({
            withCloseButton:true,
            closeOnClickOutside:true,
            closeOnEscape:true,
            size:"900",
            overflow:'outside',
            title: (
                <Text size={'xl'}>Create New Line-Item</Text>
            ),
            children: (
                <LineItemCreateForm onSubmitQuery={_onSubmitForm}/>
            ),
        })
        return false;
    }

    return (
        <Button leftIcon={<Plus size={18}/>} onClick={renderLineItemForm}>
            New Line-Item
        </Button>
    )
}
