import {
    quoteHooks,
    IQuote, QuotesListTable
} from "features/quotes";
import {Content, Page} from "components/common";
import {DefaultPageWrapper} from "layouts";
import {useState} from "react";
import {usePagination} from "@mantine/hooks";
import useChangeDocumentTitle from "hooks/use-change-document-title";

export function Quotes() {
    const [page, setPage] = useState(1)
    useChangeDocumentTitle('Quotes')
    const {isLoading: isLoadingQuotes, data: quotes} = quoteHooks.useGet({
        populate: "*",
        pagination: {
            pageSize: 10,
            page: page
        }
    })

    const pagination = usePagination({
        total: quotes?.meta.pagination.pageCount,
        initialPage: 1,
        onChange: (page: number) => setPage(page)
    })
    const {mutateAsync} = quoteHooks.useCreate();

    async function onSubmitCreate(values: IQuote) {
        return await mutateAsync(values);
    }

    return (
        <DefaultPageWrapper>
            <Page.Header>
                <Page.Title title="Quotes"/>
                {/*<QuoteCreate.Button onSubmitForm={onSubmitCreate}/>*/}
            </Page.Header>
            <Page.Body>
                <Content.Section>
                    <Content.Body>
                        <QuotesListTable
                            pagination={{
                                page: pagination.active,
                                total: quotes?.meta.pagination.pageCount,
                                onChange: pagination.setPage
                            }}
                            loading={isLoadingQuotes}
                            data={quotes?.data}/>
                    </Content.Body>
                </Content.Section>
            </Page.Body>
        </DefaultPageWrapper>
    )

}
