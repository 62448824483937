import {ModelHooks} from "services/strapi";
import { lineItemApi } from "../services/line-item.strapi.api";
import { ILineItem } from "../types/line-item.types";

class LineItemHooks extends ModelHooks<ILineItem> {
    /** Add more functions or properties here that are not described in the Base Strapi
     *  Model Hooks Service.
     **/

}

export const lineItemHooks = new LineItemHooks(lineItemApi);
