import {RouteSegments} from "./segments";
import {BaseRoutesResolver} from "utils";

export class RoutesResolver extends BaseRoutesResolver {

    constructor() {
        super('')
    }

    get dashboard() {
        return this.concatRoutesToBase([RouteSegments.Dashboard])
    }

    get users() {
        return this.concatRoutesToBase([RouteSegments.Users])
    }

    get forgotPassword() {
        return this.concatRoutesToBase([RouteSegments.Auth, RouteSegments.ForgotPassword])
    }

    get login() {
        return this.concatRoutesToBase([RouteSegments.Auth, RouteSegments.Login])
    }

    /* Generated Routes Resolver */

    getQuoteVersionById(quoteId:number, quoteVersionId: number) {
        return this.concatRoutes([this.getQuoteVersions(quoteId), quoteVersionId]);
    }


    getQuoteVersions(quoteId:number) {
        return this.concatRoutes([this.getQuoteById(quoteId), RouteSegments.QuoteVersions]);
    }


    getSupplierById(supplierId: number) {
        return this.concatRoutes([this.suppliers, supplierId]);
    }


    get suppliers() {
        return this.concatRoutesToBase([RouteSegments.Suppliers]);
    }


    getShipmentById(shipmentId: number) {
        return this.concatRoutes([this.shipments, shipmentId]);
    }


    get shipments() {
        return this.concatRoutesToBase([RouteSegments.Shipments]);
    }


    getQuoteById(quoteId: number) {
        return this.concatRoutes([this.quotes, quoteId]);
    }


    get quotes() {
        return this.concatRoutesToBase([RouteSegments.Quotes]);
    }


    getPurchaseOrderById(purchaseOrderId: number) {
        return this.concatRoutes([this.purchaseOrders, purchaseOrderId]);
    }


    get purchaseOrders() {
        return this.concatRoutesToBase([RouteSegments.PurchaseOrders]);
    }


    getProjectById(projectId: number) {
        return this.concatRoutes([this.projects, projectId]);
    }


    get projects() {
        return this.concatRoutesToBase([RouteSegments.Projects]);
    }


    getPartById(partId: number) {
        return this.concatRoutes([this.parts, partId]);
    }


    get parts() {
        return this.concatRoutesToBase([RouteSegments.Parts]);
    }


    getLineItemById(lineItemId: number) {
        return this.concatRoutes([this.lineItems, lineItemId]);
    }


    get lineItems() {
        return this.concatRoutesToBase([RouteSegments.LineItems]);
    }


    getInvoiceById(invoiceId: number) {
        return this.concatRoutes([this.invoices, invoiceId]);
    }


    get invoices() {
        return this.concatRoutesToBase([RouteSegments.Invoices]);
    }


    getFreightCarrierById(freightCarrierId: number) {
        return this.concatRoutes([this.freightCarriers, freightCarrierId]);
    }


    get freightCarriers() {
        return this.concatRoutesToBase([RouteSegments.FreightCarriers]);
    }


    getAccountById(accountId: number) {
        return this.concatRoutes([this.accounts, accountId]);
    }


    get accounts() {
        return this.concatRoutesToBase([RouteSegments.Accounts]);
    }


}
