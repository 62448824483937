import {Select, SelectProps} from "@mantine/core";
import {accountHooks} from "features/accounts";

interface Props extends Omit<SelectProps, 'data'>{
    filters: any,
}

export function AccountsSelect({filters, ...rest}: Props){
    const {
        isLoading,
        data: accounts
    } = accountHooks.useGet({
        filters: {...filters}
    })

    const accountsData = accounts?.data ? accounts.data?.map(account => ({
        value: account.id.toString(), label: account.attributes.name
    })) : []

    return (
        <Select data={accountsData} {...rest} disabled={isLoading}/>
    )
}