import {Stack} from '@mantine/core';
import {ILoginValues} from "services/strapi";
import {REMEMBER_LOGIN_LS_KEY} from 'settings';
import {useNavigate} from "react-router-dom";
import {LoginForm} from "features/auth";

interface Props {
    login: (values: ILoginValues) => any,
}

export function AuthLogin({login}: Props) {
    const navigate = useNavigate();

    const initialValues = {
        identifier: localStorage.getItem(REMEMBER_LOGIN_LS_KEY) || "",
        password: "",
        remember: !!localStorage.getItem(REMEMBER_LOGIN_LS_KEY),
    }

    async function handleSubmit(values: ILoginValues) {
        const success = await login(values);
        if (success) {
            navigate("/");
        }
    }

    return (
        <Stack sx={{minWidth: 300}}>
            <LoginForm onSubmit={handleSubmit} initialValues={initialValues}/>
            {/* <Text color="dimmed" size="sm" align="center" mt={12}> */}
            {/*     Don't have an account yet?{' '} */}
            {/*     <Anchor component={Link} size="sm" to="/auth/register"> */}
            {/*         Create an account */}
            {/*     </Anchor> */}
            {/* </Text> */}
        </Stack>
    );
}
