import {strapi} from "lib/strapi-api";
import axios from "axios";
import {
    AuthRoutes,
    IStrapiAuth,
    IStrapiAuthResponse,
    IStrapiForgotPasswordValues,
    IStrapiResetPasswordValues,
    IStrapiSendEmailConfirmationValues, IStrapiSignupValues, ModelRoutes
} from 'services/strapi/index'
import {IFlatUser} from "features/users";
import qs from "qs";

export class StrapiAuthApi<UserType> {

    async post<ResponseType,ValuesType = void>(url:AuthRoutes, values:ValuesType){
        try {
            const res = await strapi.post<ResponseType>(url, values);
            return res.data;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.data) {
                    return e.response.data?.non_field_errors?.[0]
                }
            }
            console.error(`Could not execute post for ${url}`, e);
            return undefined;
        }
    }

    async me<QueryType>(bearerToken: string, rawQuery:QueryType) {
        const headers = {'Authorization': `Bearer ${bearerToken}`};
        const query = qs.stringify(rawQuery);
        try {
            const res = await strapi.get<IFlatUser>(`/${ModelRoutes.Users}/me?${query}`, {headers});
            return res.data as IFlatUser;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error("Could not get User.me: ", e.message);
            }
            return undefined;
        }
    }

    async logout(){
        return;
    }
    async login(values: IStrapiAuth):Promise<IStrapiAuthResponse<IFlatUser>> {
        return this.post<IStrapiAuthResponse<UserType>, IStrapiAuth>(AuthRoutes.login, values);
    }
    async register(values: IStrapiSignupValues) {
        return this.post<IStrapiAuthResponse<UserType>, IStrapiSignupValues>(AuthRoutes.register, values);
    }
    async forgotPassword(values: IStrapiForgotPasswordValues) {
       return this.post<boolean, IStrapiForgotPasswordValues>(AuthRoutes.forgotPassword, values);
    }
    async resetPassword(values: IStrapiResetPasswordValues) {
        return this.post<boolean, IStrapiResetPasswordValues>(AuthRoutes.resetPassword, values);
    }
    async sendEmailConfirmation(values: IStrapiSendEmailConfirmationValues) {
        return this.post<boolean, IStrapiSendEmailConfirmationValues>(AuthRoutes.sendEmailConfirmation, values);
    }
}
