import {ListItemsTableSettings} from "components/common";
import {ActionIcon, Anchor, Text, Tooltip} from "@mantine/core";
import {RoutesResolver} from "routes";
import {IQuoteVersion} from "features/quote-versions";
import {Link} from "react-router-dom";
import {CircleCheck, CircleX} from "tabler-icons-react";
import {NumberUtils} from "utils";

interface TableSettingProps {
    routesResolver: RoutesResolver,
}

export type TTableSettings =
    (props: TableSettingProps) =>
        ListItemsTableSettings<IQuoteVersion & { id: number }, void>[];

export const TableSettings: TTableSettings = (
    {
        routesResolver,
    }: TableSettingProps) => [
    {
        key: 'versionNumber',
        label: "Version",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {versionNumber, quote, id} = data;
                if (quote?.data) {
                    return (
                        <Anchor target="_blank" component={Link} to={routesResolver.getQuoteVersionById(quote.data.id, id)}>
                            Version #{versionNumber}
                        </Anchor>
                    )
                } else {
                    return null
                }
            },
        }
    },
    {
        key: 'description',
        label: "Description",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {description} = data;
                return (
                    <Tooltip width={200} multiline label={description}>
                        <Text maw={300} lineClamp={1}>{description}</Text>
                    </Tooltip>
                )
            },
        }
    },
    {
        key: 'totalPublicListCostPrice',
        label: "Customer Cost",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {totalPublicListCostPrice} = data;
                return <Text>${NumberUtils.numberWithCommas(totalPublicListCostPrice.toString())}</Text>
            },
        }
    },
    {
        key: 'totalWholesalePrice',
        label: "Mark III Cost",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {totalWholesalePrice} = data;
                return <Text>${NumberUtils.numberWithCommas(totalWholesalePrice.toString())}</Text>
            },
        }
    },
    {
        key: 'totalProfit',
        label: "Profit",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {totalWholesalePrice, totalPublicListCostPrice} = data;
                const totalProfit = Math.round((totalPublicListCostPrice - totalWholesalePrice) * 100)/ 100
                return <Text>${NumberUtils.numberWithCommas(totalProfit.toString())}</Text>
            },
        }
    },
    {
        key: 'averageMargin',
        label: "Avg Margin",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {averageMargin} = data;
                return <Text>{averageMargin}%</Text>
            },
        }
    },
    {
        key: 'quote',
        label: "Quote",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {quote} = data;
                return (
                    <Text>{quote?.data?.attributes.internalQuoteId}</Text>
                )
            },
        }
    },
    {
        key: 'purchaseOrder',
        label: "Ordered",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {purchaseOrder} = data;
                if (purchaseOrder?.data){
                    return (
                        <ActionIcon color="green">
                            <CircleCheck size={18}/>
                        </ActionIcon>
                    )
                } else {
                    return (
                        <ActionIcon color="red">
                            <CircleX size={18}/>
                        </ActionIcon>
                    )
                }
            },
        }
    },
]