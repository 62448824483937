export enum RouteSegments {

    Dashboard = "dashboard",
    Settings = "settings",
    Users = "users",
    Overview = "overview",
    Files = "files",

    Auth = "auth",
    Login = "login",
    Register = "register",
    ForgotPassword = "forgot-password",
    AcceptInvitation = "accept-invitation",
    ResetPassword = 'reset-password',

    Wildcard = "*",
    Root = "/",

    /* Generated Route Segments */
 QuoteVersions = "quote-versions",
    Suppliers = "suppliers",
    Shipments = "shipments",
    Quotes = "quotes",
    PurchaseOrders = "purchase-orders",
    Projects = "projects",
    Parts = "parts",
    LineItems = "line-items",
    Invoices = "invoices",
    FreightCarriers = "freight-carriers",
    Accounts = "accounts",
}

export enum RouteParams {
    UserId = ":userId",

    /* Generated Route Params */
 QuoteVersionId = ":quoteVersionId",
    SupplierId = ":supplierId",
    ShipmentId = ":shipmentId",
    QuoteId = ":quoteId",
    PurchaseOrderId = ":purchaseOrderId",
    ProjectId = ":projectId",
    PartId = ":partId",
    LineItemId = ":lineItemId",
    InvoiceId = ":invoiceId",
    FreightCarrierId = ":freightCarrierId",
    AccountId = ":accountId",
}
