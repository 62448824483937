import React, {useState} from 'react';
import {
    Button,
    Group,
    Box,
    Grid,
    TextInput,
    Stack, Image
} from '@mantine/core';
import {useForm} from '@mantine/form';
import {ArrowRight} from "tabler-icons-react";
import {Dropzone} from "components/common";
import {IFlatUserDatum} from "features/user-data/index";
import { IStrapiFile } from 'services/strapi';

interface Props {
    avatar?: IStrapiFile
    onSubmitQuery?: (query: any) => void,
    initialValues?: IFlatUserDatum
}

interface FormValues {
    id?:number,
    fullName?:string,
    avatar?:IStrapiFile
}

export function UserDatumForm({
    onSubmitQuery,
    initialValues,
    avatar,
}: Props) {
    const [loading, setLoading] = useState(false);
    const form = useForm<FormValues>({
        initialValues: {
            id: initialValues?.id,
            fullName: initialValues?.fullName,
            avatar: [] as any,
        },
        validate: {},
    });

    async function onSubmit(values: typeof form.values) {
        setLoading(true);
        if (onSubmitQuery) {
            await onSubmitQuery(values)
        }
        setLoading(false);
    }

    return (
        <Box mx="auto">
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Grid gutter={24}>
                    <Grid.Col sm={3}>
                        <Stack>
                            {avatar && <Image src={avatar.url}/>}
                            <Stack spacing={3}>
                                <Dropzone {...form.getInputProps("avatar")}
                                          text="Upload Avatar"/>
                            </Stack>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col sm={9}>
                        <Grid>
                            <Grid.Col sm={12}>
                                <TextInput
                                    required
                                    label="Full Name"
                                    placeholder="Full Name"
                                    {...form.getInputProps('fullName')}
                                />
                            </Grid.Col>
                        </Grid>
                    </Grid.Col>
                </Grid>
                <Group position="right" mt="md">
                    <Button loading={loading} type="submit" rightIcon={<ArrowRight/>}>
                        {initialValues ? 'Edit User' : 'Create User'}
                    </Button>
                </Group>
            </form>
        </Box>
    )
}
