import {
    shipmentHooks,
    ShipmentCreate,
    IShipment, ShipmentsListTable
} from "features/shipments";
import {Content, Page} from "components/common";
import {DefaultPageWrapper} from "layouts";
import useChangeDocumentTitle from "hooks/use-change-document-title";

export function Shipments() {
    const {isLoading:isLoadingShipments, data: shipments } = shipmentHooks.useGet({
        populate: "*"
    })
    const {mutateAsync} = shipmentHooks.useCreate();
    useChangeDocumentTitle(`Shipments`)
    async function onSubmitCreate(values: IShipment) {
        return await mutateAsync(values);
    }


    return (
        <DefaultPageWrapper>
            <Page.Header>
                <Page.Title title="Shipments"/>
                <ShipmentCreate.Button onSubmitForm={onSubmitCreate}/>
            </Page.Header>
            <Page.Body>
                <Content.Section>
                    <Content.Body>
                        <ShipmentsListTable
                            data={shipments?.data}
                            loading={isLoadingShipments}/>
                    </Content.Body>
                </Content.Section>
            </Page.Body>
        </DefaultPageWrapper>
    )

}
