import {
    Avatar,
    createStyles,
    Group,
    Menu,
    UnstyledButton,
    Text, Stack
} from "@mantine/core";
import {ChevronDown, Logout, Settings} from "tabler-icons-react";
import {useState} from "react";
import {closeModal, openModal} from "@mantine/modals";
import {userDatumApi, userDatumHooks, UserDatumForm} from "features/user-data";
import {authHooks} from "features/auth";

const useStyles = createStyles((theme) => ({
    userMenu: {
        [theme.fn.smallerThan('xs')]: {
            display: 'none',
        },
    },

    user: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
        borderRadius: theme.radius.sm,
        transition: 'background-color 100ms ease',

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
        },
    },

    userActive: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
    },
}));

export function UserButton() {
    const {
        classes,
        cx
    } = useStyles();
    const logout = authHooks.useLogout();
    const {data:user} = authHooks.useMe();
    const [userMenuOpened, setUserMenuOpened] = useState(false);
    const {mutateAsync:updateUserDatum} = userDatumHooks.useUpdate();


    function onClickEditUser() {
        openModal({
            modalId: "editUserModal",
            title: 'Edit User',
            children: <UserDatumForm.Form
                avatar={user?.userDatum?.avatar}
                initialValues={user?.userDatum}
                onSubmitQuery={onUserEdit}/>,
            centered: true,
            closeOnClickOutside: false,
            size: 'xl'
        });
    }

    async function onUserEdit(values: any) {
        if (user && user.userDatum){
            await updateUserDatum(userDatumApi.serialize.update(user.userDatum.id, values));
        }
        closeModal('editUserModal');
    }

    async function onLogout() {
        await logout();
    }

    return (
        <Menu position="bottom-end" transition="pop-top-right"
              onClose={() => setUserMenuOpened(false)}
              onOpen={() => setUserMenuOpened(true)}>
            <Menu.Target>
                <UnstyledButton
                    className={cx(classes.user, {[classes.userActive]: userMenuOpened})}
                >
                    <Group spacing={7}>
                        <Avatar src={user?.userDatum?.avatar?.url} alt={user?.userDatum?.fullName}
                                radius="xl" size={20}
                                mr={3}/>
                        <Text weight={500} size="sm" sx={{
                            lineHeight: 1
                        }} mr={3}>
                            {user?.userDatum?.fullName}
                        </Text>
                        <ChevronDown size={12}/>
                    </Group>
                </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item>
                    <Group>
                        <Avatar src={user?.userDatum?.avatar?.url} alt={user?.userDatum?.fullName}
                                radius="xl" size={50}
                                mr={3}/>
                        <Stack spacing={6}>
                            <Text weight={600} size="sm" sx={{
                                lineHeight: 1
                            }} mr={3}>
                                {user?.userDatum?.fullName}
                            </Text>
                            <Text weight={500} size="xs" color="dimmed" sx={{
                                lineHeight: 1
                            }} mr={3}>
                                {user?.role.name}
                            </Text>
                        </Stack>
                    </Group>
                </Menu.Item>
                <Menu.Divider/>
                <Menu.Label>
                    Settings
                </Menu.Label>
                <Menu.Item icon={<Settings size={18}/>} onClick={onClickEditUser}>
                    User Settings
                </Menu.Item>
                <Menu.Divider/>
                <Menu.Item color="red" icon={<Logout size={16}/>} onClick={onLogout}>
                    <Text>Logout</Text>
                </Menu.Item>
            </Menu.Dropdown>

        </Menu>
    )
}
