import {ModelApiService, ModelRoutes} from "services/strapi/index";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

export interface UpdateValuesType<ValuesType> {
    id: number,
    values: ValuesType,
}

export class ModelHooks<ModelType> {
    private modelApiService: ModelApiService<ModelType>;

    constructor(modelApiService: ModelApiService<ModelType>) {
        this.modelApiService = modelApiService;
    }

    get modelQueryKey() {
        return this.modelApiService.route;
    }

    useGet = (query?: any) => {
        if (!query.sort){
            query.sort = ['createdAt:desc']
        }
        return useQuery(
            [this.modelQueryKey, query],
            () => this.modelApiService.findMany(query)
        );
    }

    useGetById = (id: number, query: any) => {
        return useQuery(
            [this.modelQueryKey, id, query],
            () => this.modelApiService.findOne(id, query)
        );
    }

    useGetBySlug = (slug:string, query: any) => {
        return useQuery(
            [this.modelQueryKey, slug, query],
            () => this.modelApiService.findOneBySlug(slug, query)
        );
    }

    useCreate = <ValuesType>(extraInvalidateKeys:ModelRoutes[] = []) => {
        const queryClient = useQueryClient();
        return useMutation(
            (values: ValuesType) => this.modelApiService.create<ValuesType>(values),
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries([this.modelQueryKey])
                    for (let i = 0; i < extraInvalidateKeys.length; i++) {
                        const extraInvalidateKey = extraInvalidateKeys[i];
                        await queryClient.invalidateQueries([extraInvalidateKey])
                    }
                }
            }
        );
    }

    useUpdate = <ValuesType>(extraInvalidateKeys:ModelRoutes[] = []) => {
        const queryClient = useQueryClient();
        return useMutation(
            (payload: UpdateValuesType<ValuesType>) => this.modelApiService.update<ValuesType>(payload.id, payload.values), {
                onSuccess: async () => {
                    await queryClient.invalidateQueries([this.modelQueryKey])
                    for (let i = 0; i < extraInvalidateKeys.length; i++) {
                        const extraInvalidateKey = extraInvalidateKeys[i];
                        await queryClient.invalidateQueries([extraInvalidateKey])
                    }
                }
            }
        );
    }

    useDelete = (extraInvalidateKeys:ModelRoutes[] = []) => {
        const queryClient = useQueryClient();
        return useMutation(
            (id: number) => this.modelApiService.delete(id), {
                onSuccess: async () => {
                    await queryClient.invalidateQueries([this.modelQueryKey])
                    for (let i = 0; i < extraInvalidateKeys.length; i++) {
                        const extraInvalidateKey = extraInvalidateKeys[i];
                        await queryClient.invalidateQueries([extraInvalidateKey])
                    }
                }
            }
        );
    }
}
