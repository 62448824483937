import {useState} from 'react';
import {Box, Button, Grid, Group, TextInput} from '@mantine/core';
import {useForm} from '@mantine/form';
import {ArrowRight} from "tabler-icons-react";
import { IShipment } from "features/shipments"
import {
    FreightCarriersSelect
} from "features/freight-carriers/components/freight-carriers.select/freight-carriers.select";
import {DatePicker} from "@mantine/dates";

interface Props {
    onSubmitQuery?: (query: any) => void,
    initialValues?: any
}

export function ShipmentCreateForm({onSubmitQuery, initialValues}: Props) {
    const [creatingShipment, setCreatingShipment] = useState(false);
    const form = useForm<IShipment>({
        initialValues: initialValues || {},
        validate: {},
    });


    async function onSubmit(values: typeof form.values) {
        if (onSubmitQuery) {
            setCreatingShipment(true);
            await onSubmitQuery(values)
            setCreatingShipment(false);
        }
    }


    return (
        <Box mx="auto">
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Grid>
                    <Grid.Col sm={12}>
                        <TextInput label="Tracking Number" {...form.getInputProps('trackingNumber')}/>
                    </Grid.Col>
                    <Grid.Col sm={12}>
                        <DatePicker label="Delivery Date" {...form.getInputProps('deliveryDate')}/>
                    </Grid.Col>
                    <Grid.Col sm={12}>
                        <TextInput label="Signed For" {...form.getInputProps('signedFor')}/>
                    </Grid.Col>
                    <Grid.Col sm={12}>
                        <FreightCarriersSelect label="Freight Carrier" {...form.getInputProps('freightCarrier')}/>
                    </Grid.Col>
                    <Grid.Col sm={12}>
                        <Group position="right" mt="md">
                            <Button type="submit" loading={ creatingShipment }
                                    rightIcon={<ArrowRight/>}>
                                Add New Shipment
                            </Button>
                        </Group>
                    </Grid.Col>
                </Grid>
            </form>
        </Box>
    )
}
