import {ExternalIdDisplay, ListItemsTableSettings} from "components/common";
import {Anchor, Group, Text} from "@mantine/core";
import {RoutesResolver} from "routes";
import {Link} from "react-router-dom";
import {IQuote, QuoteStatusMap, QuoteTypeMap} from "features/quotes";
import dayjs from "dayjs";
import {GLOBAL_DATE_FORMAT} from "settings";
import {UserAvatar} from "features/users/components";
import React from "react";

interface TableSettingProps {
    routesResolver: RoutesResolver,
}

export type TTableSettings =
    (props: TableSettingProps) =>
        ListItemsTableSettings<IQuote & { id: number }, void>[];

export const TableSettings: TTableSettings = (
    {
        routesResolver,
    }: TableSettingProps) => [
    {
        key: 'name',
        label: "Name",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {name, id} = data;
                return (
                    <Anchor target="_blank" to={routesResolver.getQuoteById(id)} component={Link}>{name}</Anchor>
                )
            },
        }
    },
    {
        key: 'externalId',
        label: "Opportunity ID",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {externalId, id} = data;
                return (
                    <ExternalIdDisplay externalId={externalId}/>
                )
            },
        }
    },
    {
        key: 'status',
        label: "Status",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {status} = data;
                if (status) {
                    const QuoteStatus = QuoteStatusMap[status]
                    return (
                        <Group spacing={3}>
                            <QuoteStatus.Icon size={16} color={QuoteStatus.color}/>
                            {QuoteStatus.text}
                        </Group>
                    )
                } else {
                    return null
                }
            },
        }
    },
    // {
    //     key: 'type',
    //     label: "Type",
    //     isNotFormValue: true,
    //     Render: {
    //         Static: function ({data}) {
    //             const {type} = data;
    //             if (type) {
    //                 const QuoteType = QuoteTypeMap[type]
    //                 return (
    //                     <Group spacing={3}>
    //                         <QuoteType.Icon size={16}/>
    //                         {QuoteType.text}
    //                     </Group>
    //                 )
    //             } else {
    //                 return null
    //             }
    //         },
    //     }
    // },
    {
        key: 'account',
        label: "Account",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {account} = data;
                if (account?.data) {
                    return (
                        <Anchor component={Link} to={routesResolver.getAccountById(account.data.id)}>
                            {account.data.attributes.name}
                        </Anchor>
                    )
                } else {
                    return (
                        <Text size="sm">
                            {account?.data?.attributes.name}
                        </Text>
                    )
                }
            },
        }
    },
    {
        key: 'project',
        label: "Project",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {project} = data;
                if (project?.data) {
                    return (
                        <Anchor to={routesResolver.getProjectById(project?.data?.id)}
                                component={Link}>{project?.data?.attributes.name}</Anchor>
                    )
                } else {
                    return null
                }
            },
        }
    },
    {
        key: 'salesRep',
        label: "Account Executive",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {salesRep} = data;
                return <UserAvatar user={salesRep?.data?.attributes}/>
            },
        }
    },
    {
        key: 'insideSalesRep',
        label: "Sales Rep",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {insideSalesRep} = data;
                return <UserAvatar user={insideSalesRep?.data?.attributes}/>
            },
        }
    },
    {
        key: 'followUp',
        label: "Follow Up",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {followUp} = data;
                if (followUp){
                    return <Text>{dayjs(followUp).format(GLOBAL_DATE_FORMAT)}</Text>
                } else {
                    return <Text color='dimmed'>None</Text>
                }

            },
        }
    },
]