import {IShipment, ShipmentCreate, shipmentHooks, ShipmentsListTable, ShipmentsTable} from "features/shipments";
import {Content, LoaderDefault, NoData, Page} from "components/common";
import {Group, Paper, Title} from "@mantine/core";
import {DefaultPageWrapper} from "layouts";
import {useParams} from "react-router-dom";
import useChangeDocumentTitle from "hooks/use-change-document-title";

export function QuoteShipments() {
    const {quoteId} = useParams();
    const {isLoading: isLoadingShipments, data: shipments} = shipmentHooks.useGet({
        populate: "*",
        filters: {
            quote: {
                id: parseInt(quoteId!)
            }
        }
    })
    const {mutateAsync} = shipmentHooks.useCreate();
    useChangeDocumentTitle(`Shipments`)
    async function onSubmitCreate(values: IShipment) {
        return await mutateAsync({
            ...values,
            quote: quoteId
        });
    }

    return (
        <DefaultPageWrapper>
            <Page.Header>
                <Page.Title title="Shipments"/>
                <ShipmentCreate.Button onSubmitForm={onSubmitCreate}/>
            </Page.Header>
            <Page.Body>
                <Content.Section>
                    <Content.Body>
                        <ShipmentsListTable
                            data={shipments?.data}
                            loading={isLoadingShipments}/>
                    </Content.Body>
                </Content.Section>
            </Page.Body>
        </DefaultPageWrapper>
    )
}