import {Stack, Title, Group, ActionIcon, Tooltip} from "@mantine/core";
import {Link, Outlet, useParams} from "react-router-dom";
import {accountHooks} from "features/accounts";
import {LoaderDefault, NoData, Page} from "components/common";
import {DefaultPageWrapper} from "layouts";
import {ArrowLeft} from "tabler-icons-react";
import {RoutesResolver} from "routes";

export function Account() {
    const {accountId} = useParams();
    const {isLoading, data: account} = accountHooks.useGetById(parseInt(accountId!), {});

    function renderContent() {
        if (isLoading) {
            return <LoaderDefault text="Loading Account"/>
        } else if (!account) {
            return <NoData title="No Account with specified ID found"/>
        } else {
            return <Outlet/>
        }
    }

    const routesResolver = new RoutesResolver();

    return (
        <DefaultPageWrapper>
            <Page.Header>
                <Page.Title loading={isLoading}
                            title={account?.data.attributes.name}
                            link={{
                                to: routesResolver.accounts,
                                label: "Back to Accounts"
                            }}/>
            </Page.Header>
        </DefaultPageWrapper>
    )
}
