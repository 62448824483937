import {ModelHooks} from "services/strapi";
import { quoteVersionApi } from "../services/quote-version.strapi.api";
import { IQuoteVersion } from "../types/quote-version.types";

class QuoteVersionHooks extends ModelHooks<IQuoteVersion> {
    /** Add more functions or properties here that are not described in the Base Strapi
     *  Model Hooks Service.
     **/

}

export const quoteVersionHooks = new QuoteVersionHooks(quoteVersionApi);
