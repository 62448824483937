import {ModelHooks} from "services/strapi";
import { projectApi } from "../services/project.strapi.api";
import { IProject } from "../types/project.types";

class ProjectHooks extends ModelHooks<IProject> {
    /** Add more functions or properties here that are not described in the Base Strapi
     *  Model Hooks Service.
     **/

}

export const projectHooks = new ProjectHooks(projectApi);
