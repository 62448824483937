import {Button, Text} from "@mantine/core";
import { InvoiceCreateForm } from "./invoice.create.form";
import { useModals } from "@mantine/modals";
import { Plus } from "tabler-icons-react";

interface Props {
    onSubmitForm:(values:any) => void,
}

export function InvoiceCreateButton({ onSubmitForm }:Props) {
    const modals = useModals();

    async function _onSubmitForm(values:any){
        await onSubmitForm(values);
        modals.closeAll();
    }

    const renderInvoiceForm = () => {
        modals.openModal({
            withCloseButton:true,
            closeOnClickOutside:true,
            closeOnEscape:true,
            size:700,
            overflow:'outside',
            title: (
                <Text size={'xl'}>Create New Invoice</Text>
            ),
            children: (
                <InvoiceCreateForm onSubmitQuery={_onSubmitForm}/>
            ),
        })
        return false;
    }

    return (
        <Button leftIcon={<Plus size={18}/>} onClick={renderInvoiceForm}>
            New Invoice
        </Button>
    )
}
