import {Select, SelectProps} from "@mantine/core";
import {userHooks} from "features/users";

interface Props extends Omit<SelectProps, 'data'>{
    filters: any,
}

export function UsersSelect({filters, ...rest}: Props){
    const {
        isLoading,
        data: users
    } = userHooks.useUsers({
        filters: {...filters}
    })

    const usersData = users?.data ? users.data?.map(user => ({
        value: user.id.toString(), label: user.userDatum?.fullName || user.email
    })) : []

    return (
        <Select data={usersData} disabled={isLoading} {...rest}/>
    )
}