import {
    IPurchaseOrder,
    PurchaseOrderCreate,
    purchaseOrderHooks,
    PurchaseOrdersListTable,
    PurchaseOrdersTable
} from "features/purchase-orders";
import {Content, Page} from "components/common";
import {DefaultPageWrapper} from "layouts";
import {useParams} from "react-router-dom";
import useChangeDocumentTitle from "hooks/use-change-document-title";

export function QuotePurchaseOrders() {
    const {quoteId} = useParams();
    const {isLoading: isLoadingPurchaseOrders, data: purchaseOrders} = purchaseOrderHooks.useGet({
        populate: "*",
        filters: {
            quote: parseInt(quoteId!)
        }
    })
    useChangeDocumentTitle(`Purchase Orders`)
    const {mutateAsync} = purchaseOrderHooks.useCreate();

    async function onSubmitCreate(values: IPurchaseOrder) {
        return await mutateAsync({
            ...values,
            quote: quoteId
        });
    }

    return (
        <DefaultPageWrapper>
            <Page.Header>
                <Page.Title title="Purchase Orders"/>
            </Page.Header>
            <Page.Body>
                <Content.Section>
                    <Content.Body>
                        <PurchaseOrdersListTable
                            disableFields={['quote']}
                            loading={isLoadingPurchaseOrders}
                            data={purchaseOrders?.data}/>
                    </Content.Body>
                </Content.Section>
            </Page.Body>
        </DefaultPageWrapper>
    )
}