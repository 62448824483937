import {CONTENT_ALIGN, DEFAULT_MAX_CONTENT_WIDTH} from "settings";
import {Stack, Tabs} from "@mantine/core";
import {ITabLink} from "components/common/tabs/types";

interface Props {
    tab?: string,
    onTabChange: (v: string) => void,
    links: ITabLink[]
}

export function TabsWrapper({tab, onTabChange, links}: Props) {
    return (
        <Stack align={CONTENT_ALIGN} sx={{width: "100%"}}>
            <Stack sx={{maxWidth: DEFAULT_MAX_CONTENT_WIDTH, width: "100%"}}>
                <Tabs value={tab} onTabChange={onTabChange}
                      sx={(theme) => ({
                          width: "100%",
                          ".mantine-Tabs-tab": {
                              fontSize:12,
                              letterSpacing: "0.01em",
                              '&[data-active]': {
                                  fontWeight: 600,
                                  ".mantine-Text-root": {
                                      color: `${theme.colorScheme === "dark" ? theme.colors.gray[0] : theme.colors.dark[9]}`,
                                  },
                                  ".mantine-Tabs-tabIcon": {
                                      color: `${theme.colors.green[6]}`,
                                  },
                              },
                              "&:hover": {
                                  backgroundColor:"transparent"
                              },
                              "&:first-of-type": {
                                  marginLeft: 12
                              }
                          }
                      })}>
                    <Tabs.List>
                        {links.filter(link => !link.disabled).map((link, i) => (
                            <Tabs.Tab ml={i === 0 ? 16 : "initial"} key={link.value} value={link.value}
                                      icon={link.icon}>
                                {link.label}
                            </Tabs.Tab>
                        ))}
                    </Tabs.List>
                </Tabs>
            </Stack>
        </Stack>
    )
}