const userFiltersSerializableDates: string[] = []
const userFiltersSerializableJoins: string[] = []
const qsMap = {name: "$containsi"}

export const userFiltersSettings = {
    serializable: {
        dates: userFiltersSerializableDates,
        joins: userFiltersSerializableJoins
    },
    qsMap
};
