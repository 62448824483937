import {useState} from 'react';
import {Box, Button, Grid, Group, Textarea, TextInput} from '@mantine/core';
import {useForm} from '@mantine/form';
import {ArrowRight} from "tabler-icons-react";
import { IQuoteVersion } from "features/quote-versions"

interface Props {
    onSubmitQuery?: (query: any) => void,
    initialValues?: any
}

export function QuoteVersionCreateForm({onSubmitQuery, initialValues}: Props) {
    const [creatingQuoteVersion, setCreatingQuoteVersion] = useState(false);
    const form = useForm<IQuoteVersion>({
        initialValues: initialValues || {},
        validate: {},
    });


    async function onSubmit(values: typeof form.values) {
        if (onSubmitQuery) {
            setCreatingQuoteVersion(true);
            await onSubmitQuery(values)
            setCreatingQuoteVersion(false);
        }
    }


    return (
        <Box mx="auto">
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Grid>
                    <Grid.Col>
                        <Textarea label="Description" {...form.getInputProps('description')}/>
                    </Grid.Col>
                    <Grid.Col sm={12}>
                        <Group position="right" mt="md">
                            <Button type="submit" loading={ creatingQuoteVersion }
                                    rightIcon={<ArrowRight/>}>
                                Submit Quote Version
                            </Button>
                        </Group>
                    </Grid.Col>
                </Grid>
            </form>
        </Box>
    )
}
