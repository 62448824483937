import {IStrapiEntity, IStrapiFile, IStrapiRelationship } from "services/strapi";
import {IUser} from "features/users";
import {ILineItem} from "features/line-items";
import {IAccount} from "features/accounts";
import {IProject} from "features/projects";
import { IPurchaseOrder } from "features/purchase-orders";
import {IInvoice} from "features/invoices";
import {CircleCheck, CircleX, Flame, FlameOff, HandGrab, ListNumbers, Loader3, Pencil, ShoppingCart} from "tabler-icons-react";

export interface IQuote {
    name:string,
    externalId:string,
    status?: EQuoteStatus,
    type?: EQuoteType,
    expirationDate?: any,
    internalQuoteId?: string,
    version?: number,
    followUp?: any,
    isStandard?: boolean,
    contact?: IStrapiRelationship<IStrapiEntity<IUser>>
    lineItems?: IStrapiRelationship<IStrapiEntity<ILineItem>[]>,
    account?: IStrapiRelationship<IStrapiEntity<IAccount>>,
    salesRep?: IStrapiRelationship<IStrapiEntity<IUser>>,
    documents?: IStrapiRelationship<IStrapiEntity<IStrapiFile>[]>,
    notes?: string,
    project?: IStrapiRelationship<IStrapiEntity<IProject>>,
    totalPrice?: number,
    customerQuoteDocuments?: IStrapiRelationship<IStrapiEntity<IStrapiFile>[]>,
    vendorQuoteDocuments?: IStrapiRelationship<IStrapiEntity<IStrapiFile>[]>,
    customerPoDocuments?: IStrapiRelationship<IStrapiEntity<IStrapiFile>[]>,
    customerInvoiceDocuments?: IStrapiRelationship<IStrapiEntity<IStrapiFile>[]>,
    otherDocuments?: IStrapiRelationship<IStrapiEntity<IStrapiFile>[]>,
    purchaseOrders?: IStrapiRelationship<IStrapiEntity<IPurchaseOrder>[]>,
    invoices?: IStrapiRelationship<IStrapiEntity<IInvoice>[]>
    insideSalesRep?: IStrapiRelationship<IStrapiEntity<IUser>>,
    customerPO?: string,
    internalMasterPO?: string
}

export interface IQuoteForm extends Omit<IQuote,
    'contact'|
    'lineItems'|
    'account'|
    'salesRep'|
    'documents'|
    'project'|
    'customerQuoteDocuments'|
    'vendorQuoteDocuments'|
    'customerPoDocuments'|
    'customerInvoiceDocuments'|
    'otherDocuments'|
    'purchaseOrders'|
    'invoices'|
    'insideSalesRep'
> {
    contact?:string,
    account?:string,
    salesRep?:string,
    project?:string,
    insideSalesRep?:string
}

export enum EQuoteStatus {
    Active = "active",
    Pending = "pending",
    Holding = "holding",
    Complete = "complete",
    Cancelled = "cancelled",
    Inactive = "inactive",
}

export const QuoteStatusMap:Record<EQuoteStatus, { text: string, Icon: any, color: string }> = {
    [EQuoteStatus.Active]: {
        text: "Active",
        Icon: Flame,
        color: "blue",
    },
    [EQuoteStatus.Complete]: {
        text: "Complete",
        Icon: CircleCheck,
        color: "green",
    },
    [EQuoteStatus.Cancelled]: {
        text: "Cancelled",
        Icon: CircleX,
        color: "red",
    },
    [EQuoteStatus.Holding]: {
        text: "Holding",
        Icon: HandGrab,
        color: "orange",
    },
    [EQuoteStatus.Inactive]: {
        text: "Inactive",
        Icon: FlameOff,
        color: "gray",
    },
    [EQuoteStatus.Pending]: {
        text: "Pending",
        Icon: Loader3,
        color: "indigo",
    },
}

export enum EQuoteType {
    Draft = "draft",
    Quote = "quote",
    Order = "order"
}

export const QuoteTypeMap:Record<EQuoteType, { text: string, Icon: any, color: string }> = {
    [EQuoteType.Draft]: {
        text: "Draft",
        Icon: Pencil,
        color: "red",
    },
    [EQuoteType.Quote]: {
        text: "Quote",
        Icon: ListNumbers,
        color: "yellow",
    },
    [EQuoteType.Order]: {
        text: "Order",
        Icon: ShoppingCart,
        color: "green",
    },
}

export enum EQuoteFileFields {
    CustomerQuoteDocuments = "customerQuoteDocuments",
    VendorQuoteDocuments = "vendorQuoteDocuments",
    CustomerPODocuments = "customerPoDocuments",
    CustomerInvoiceDocuments = "customerInvoiceDocuments",
    OtherDocuments = "otherDocuments"
}

