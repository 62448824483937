export enum ModelRoutes {
    /* Generated Model Routes */
    QuoteVersions = "quote-versions",
    Suppliers = "suppliers",
    Shipments = "shipments",
    Quotes = "quotes",
    PurchaseOrders = "purchase-orders",
    Projects = "projects",
    Parts = "parts",
    LineItems = "line-items",
    Invoices = "invoices",
    FreightCarriers = "freight-carriers",
    Accounts = "accounts",
    UserData = 'user-data',
    Users = 'users',
}
