import {ActionIcon, Button, Group, Tooltip} from "@mantine/core";
import {ArrowRight, Edit, X} from "tabler-icons-react";
import React from "react";

interface Props {
    tooltipLabel?: string,
    onSubmitEdit?: () => void,
    loading: boolean,
    saveChangesButtonEnabled?: boolean,
    onToggleEdit: () => void,
    showEdit: boolean
    onCancel?:() => void,
    hideActionButtons?: boolean
}

export function EditToggle(
    {
        tooltipLabel,
        onSubmitEdit,
        loading,
        saveChangesButtonEnabled,
        showEdit,
        onToggleEdit,
        onCancel,
        hideActionButtons
    }:Props) {

    function _onCancel(){
        if (onCancel){
            onCancel()
        }
        onToggleEdit()
    }

    if (showEdit) {
        if(hideActionButtons) return null
        return (
            <Group spacing={6}>
                <Button leftIcon={<X size={18}/>} color="gray" variant="subtle" size="xs"
                        onClick={_onCancel}>
                    Cancel
                </Button>
                <Button
                    onClick={onSubmitEdit}
                    loading={loading}
                    disabled={saveChangesButtonEnabled}
                    size="xs" rightIcon={<ArrowRight size={18}/>}>
                    Save Changes
                </Button>
            </Group>
        )
    } else {
        return (
            <Tooltip label={tooltipLabel ? tooltipLabel : "Edit"}>
                <ActionIcon size="sm" onClick={onToggleEdit}>
                    <Edit size={24}/>
                </ActionIcon>
            </Tooltip>
        )
    }
}