import {
    invoiceHooks,
    InvoiceCreate,
    IInvoice, InvoicesListTable
} from "features/invoices";

import {Content, Page} from "components/common";
import {DefaultPageWrapper} from "layouts";
import useChangeDocumentTitle from "hooks/use-change-document-title";

export function Invoices() {
    const {isLoading: isLoadingInvoices, data: invoices } = invoiceHooks.useGet({
        populate: "*"
    })
    const {mutateAsync} = invoiceHooks.useCreate();
    useChangeDocumentTitle(`Invoices`)
    async function onSubmitCreate(values: IInvoice) {
        return await mutateAsync(values);
    }

    return (
        <DefaultPageWrapper>
            <Page.Header>
                <Page.Title title="Invoices"/>
                <InvoiceCreate.Button onSubmitForm={onSubmitCreate}/>
            </Page.Header>
            <Page.Body>
                <Content.Section>
                    <Content.Body>
                        <InvoicesListTable
                            disableFields={['quote']}
                            data={invoices?.data}
                            loading={isLoadingInvoices}/>
                    </Content.Body>
                </Content.Section>
            </Page.Body>
        </DefaultPageWrapper>
    )

}
