import {ModelHooks} from "services/strapi";
import { shipmentApi } from "../services/shipment.strapi.api";
import { IShipment } from "../types/shipment.types";

class ShipmentHooks extends ModelHooks<IShipment> {
    /** Add more functions or properties here that are not described in the Base Strapi
     *  Model Hooks Service.
     **/

}

export const shipmentHooks = new ShipmentHooks(shipmentApi);
