import {Table,} from "@mantine/core";
import {IStrapiEntity} from "services/strapi";
import { ILineItem } from "features/line-items";
import {useState} from "react";

interface Props {
    lineItems: IStrapiEntity<ILineItem>[],
    onSort?: (field: keyof ILineItem, reversed: boolean) => void,
}

export function LineItemsTable({ lineItems, onSort }: Props) {
    // const [sortBy, setSortBy] = useState<keyof ILineItem | null>(null);
    // const [reverseSortDirection, setReverseSortDirection] = useState(false);

    const rows = lineItems.map((lineItem) => {
        // eslint-disable-next-line
        const {/* quantity, wholesaleCost, marginPercentage, publicListCost, quote, part */} = lineItem.attributes;
        return (
            <tr key={ lineItem.id }>

            </tr>
        )
    });

    return (
        <Table>
            <thead>
            <tr>

            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    )
}
