import {ActionIcon, Code, CopyButton, Group, Text, Tooltip} from "@mantine/core";
import {Check, Copy} from "tabler-icons-react";
import React from "react";

interface Props {
    externalId?: string
}

export function ExternalIdDisplay({externalId}: Props) {
    if (!externalId){
        return <Text>None</Text>
    }
    return (
        <Group noWrap spacing="xs">
            <Code block={false} color="blue">
                {externalId}
            </Code>
            <CopyButton value={externalId} timeout={2000}>
                {({copied, copy}) => (
                    <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                        <ActionIcon size="sm" color={copied ? 'teal' : 'gray'} onClick={copy}>
                            {copied ? <Check size={16}/> : <Copy size={16}/>}
                        </ActionIcon>
                    </Tooltip>
                )}
            </CopyButton>
        </Group>
    )
}