export const TOAST_DURATION = 10;
export const ERROR_TOAST_DURATION = 20;

export const GLOBAL_DATETIME_FORMAT = "MMM DD, YY - hh:mm A";
export const GLOBAL_DATE_FORMAT = "MMM DD, YYYY";

export const DEFAULT_MAX_CONTENT_WIDTH = 1200;

export const CONTENT_ALIGN = "left"
export const COMMON_MODAL_PROPS = {
    centered: true,
    withCloseButton:false,
    closeOnClickOutside:true,
    closeOnEscape:true,
};

export function getHeightForScroll(extraSpace?:number){
    if (extraSpace){
        return `calc(100vh - ${extraSpace}px - var(--mantine-header-height, 0px) - var(--mantine-footer-height, 0px))`
    } else {
        return "calc(100vh - var(--mantine-header-height, 0px) - var(--mantine-footer-height, 0px))"
    }
}
