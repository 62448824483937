import {Button, Modal} from "@mantine/core";
import {Plus} from "tabler-icons-react";
import {useState} from "react";
import {UserDatumForm} from "./user-datum.form";

interface Props {
    onSubmit:(values:any) =>void,
}

export function UserDatumFormButton({onSubmit}:Props) {
    const [opened, setOpened] = useState(false);

    async function onSubmitQuery(values: any) {
        await onSubmit(values);
        setOpened(false);
    }

    return (

        <>
            <Button leftIcon={<Plus size={18}/>} onClick={() => setOpened(true)}>
                New Organization
            </Button>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                title="Create Organization"
                padding="xl"
                size="xl"
                centered
            >
                <UserDatumForm onSubmitQuery={onSubmitQuery}/>
            </Modal>
        </>
    )
}
