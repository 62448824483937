import {Stack, Title, Group, ActionIcon, Tooltip} from "@mantine/core";
import {Link, Outlet, useParams} from "react-router-dom";
import { lineItemHooks } from "features/line-items";
import {LoaderDefault, NoData} from "components/common";
import {DefaultPageWrapper} from "layouts";
import {ArrowLeft} from "tabler-icons-react";
import {RoutesResolver} from "routes";

export function LineItem() {
    const { lineItemId } = useParams();
    const {isLoading, data: lineItem } = lineItemHooks.useGetById(parseInt(lineItemId!), {

    });

    function renderContent() {
        if (isLoading) {
            return <LoaderDefault text="Loading Line-Item"/>
        } else if (!lineItem) {
            return <NoData title="No Line-Item with specified ID found"/>
        } else {
            return <Outlet/>
        }
    }

    const routesResolver = new RoutesResolver();

    return (
        <Stack spacing={0}>
            <DefaultPageWrapper>
                <Group spacing="sm">
                    <Tooltip label="Back to Line-Items" withArrow>
                        <ActionIcon component={Link} size="sm" to={ routesResolver.lineItems }>
                            <ArrowLeft size={18}/>
                        </ActionIcon>
                    </Tooltip>
                    <Title>Insert Line-Item Title Attribute</Title>
                </Group>
            </DefaultPageWrapper>
            <Stack>
                {renderContent()}
            </Stack>
        </Stack>
    )

}
