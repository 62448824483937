import {useNavigate, useParams} from "react-router-dom";
import {Content, EditToggle, Page} from "components/common";
import {DefaultPageWrapper} from "layouts";
import {RoutesResolver} from "routes";
import {LineItemsMultiList} from "features/line-items/components/line-items.multi.list";
import {useForm} from "@mantine/form";
import {Alert, Button, Grid, Group, Text, useMantineTheme} from "@mantine/core";
import {ILineItemListForm, lineItemHooks, LineItemsListTable} from "features/line-items";
import {Dropzone, FileWithPath, MIME_TYPES} from "@mantine/dropzone";
import {ArrowBigUpLines, CircleCheck, FileSpreadsheet, Trash, Upload, X} from "tabler-icons-react";
import Papa from 'papaparse'
import {useEffect, useState} from "react";
import {ModelRoutes, StrapiUtils} from "services/strapi";
import {quoteVersionHooks} from "features/quote-versions";
import {purchaseOrderHooks} from "features/purchase-orders";
import {openConfirmModal} from "@mantine/modals";
import currency from 'currency.js'
import useChangeDocumentTitle from "hooks/use-change-document-title";

export function QuoteVersion() {
    const {quoteVersionId} = useParams();
    const theme = useMantineTheme();
    const navigate = useNavigate()
    const [submitting, setSubmitting] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const {
        isLoading: isLoadingQuoteVersion,
        data: quoteVersion
    } = quoteVersionHooks.useGetById(parseInt(quoteVersionId!), {
        populate: "*"
    });
    useChangeDocumentTitle(`Version #${quoteVersion?.data.attributes.versionNumber}`)
    const {
        isLoading: isLoadingLineItems,
        data: lineItems
    } = lineItemHooks.useGet({
        filters: {
            quoteVersion: parseInt(quoteVersionId!)
        },
        populate: "*"
    });
    const routesResolver = new RoutesResolver();
    const lineItemsForm = useForm<{ items: ILineItemListForm[] }>({
        initialValues: {
            items: []
        }
    })
    const {
        mutateAsync: createLineItem
    } = lineItemHooks.useCreate([ModelRoutes.QuoteVersions])
    const {
        mutateAsync: updateLineItem
    } = lineItemHooks.useUpdate([ModelRoutes.QuoteVersions])
    const {
        mutateAsync: createPurchaseOrder,
        isLoading: isCreatingPurchaseOrder
    } = purchaseOrderHooks.useCreate([ModelRoutes.QuoteVersions])
    const {
        mutateAsync: deleteQuoteVersion,
        isLoading: isDeletingQuoteVersion
    } = quoteVersionHooks.useDelete()

    async function onDrop(files: FileWithPath[]) {
        const file = files[0]
        const transformHeaders: Record<string, any> = {
            "Item": {
                key: "partDescription"
            },
            "ItemCode": {
                key: "partNumber"
            },
            "Item Quantity": {
                key: "quantity",
                shouldParseFloat: true,
            },
            "Item Unit Price": {
                key: "wholesaleCost",
                shouldParseCurrency: true,
            },
        }

        function transformToObject(row: Record<string, any>) {
            const object: any = {}
            Object.keys(row).forEach(rowKey => {
                if (transformHeaders[rowKey]) {
                    const {key, shouldParseFloat, shouldParseCurrency} = transformHeaders[rowKey]
                    if (shouldParseFloat) {
                        object[key] = parseFloat(row[rowKey])
                    } else if (shouldParseCurrency) {
                        object[key] = currency(row[rowKey]).value
                    } else {
                        object[key] = row[rowKey]
                    }
                }
            })
            return object
        }

        try {
            Papa.parse<string[]>(file, {
                worker: true,
                skipEmptyLines: true,
                header: true,
                complete({data}) {
                    data.forEach(datum => {
                        const transformed = transformToObject(datum)
                        const {wholesaleCost} = transformed
                        const publicListCost = wholesaleCost + ((10 / 100) * wholesaleCost)
                        lineItemsForm.insertListItem('items', {
                            ...transformToObject(datum),
                            marginPercentage: 10,
                            publicListCost
                        })
                    })
                },
            });
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (lineItems?.data && lineItems.data.length > 0) {
            lineItemsForm.setFieldValue(
                'items',
                StrapiUtils.flattenStrapiEntities(lineItems.data) as ILineItemListForm[]
            )
        }
    }, [lineItems?.data])

    async function onSubmitChanges() {
        setSubmitting(true)
        const {items} = lineItemsForm.values
        if (items.length > 0) {
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.id) {
                    await updateLineItem({
                        id: item.id,
                        values: item
                    })
                } else {
                    await createLineItem({
                        ...item,
                        quoteVersion: parseInt(quoteVersionId!)
                    })
                }
            }
        }
        setShowEdit(false)
        setSubmitting(false)
    }

    function onDeleteVersion() {
        if (quoteVersion) {
            openConfirmModal({
                title: `Confirm Version Deletion`,
                children: (
                    <Text size="sm">
                        You are about to delete this version. This is irreversible, and the line items will no longer
                        exist.
                    </Text>
                ),
                labels: {
                    confirm: `Yes, remove`,
                    cancel: 'Cancel'
                },
                confirmProps: {color: 'red'},
                centered: true,
                onConfirm: async () => {
                    if (quoteVersion.data.attributes.quote?.data?.id) {
                        navigate(routesResolver.getQuoteVersions(quoteVersion.data.attributes.quote?.data?.id))
                        await deleteQuoteVersion(quoteVersion.data.id)
                    }
                },
            });
        }
    }

    async function onClickPromoteToPO() {
        if (quoteVersion?.data.attributes.quote?.data) {
            await createPurchaseOrder({
                quoteVersion: parseInt(quoteVersionId!),
                quote: quoteVersion?.data.attributes.quote?.data.id
            })
        }

    }

    if (!quoteVersion?.data.attributes.quote?.data) {
        return null
    }

    return (
        <DefaultPageWrapper>
            <Page.Header>
                <Page.Title
                    loading={isLoadingQuoteVersion}
                    title={`${quoteVersion?.data.attributes.quote?.data?.attributes.internalQuoteId}.v${quoteVersion?.data.attributes.versionNumber}`}
                    link={{
                        label: "Back to Quote Versions",
                        to: routesResolver.getQuoteVersions(quoteVersion.data.attributes.quote.data.id)
                    }}/>
                <Group>
                    {!quoteVersion.data.attributes.purchaseOrder?.data &&
                        <Button onClick={onDeleteVersion} variant="subtle" color="red" leftIcon={<Trash size={18}/>}>
                            Delete Version
                        </Button>}
                    <Button loading={isCreatingPurchaseOrder}
                            disabled={!!quoteVersion.data.attributes.purchaseOrder?.data || !lineItems?.data.length}
                            onClick={onClickPromoteToPO}
                            leftIcon={quoteVersion.data.attributes.purchaseOrder?.data ? <CircleCheck size={18}/> :
                                <ArrowBigUpLines size={18}/>}>
                        {quoteVersion.data.attributes.purchaseOrder?.data ? "Purchase Order Submitted" : "Submit as Purchase Order"}
                    </Button>
                </Group>
            </Page.Header>
            {quoteVersion.data.attributes.purchaseOrder?.data &&
                <Alert color="blue" mb={24} title="This Quote Version has been submitted as a Purchase Order">
                    As a result, you can not edit the line items, nor are you able to submit it as a Purchase Order
                    again. If you'd like to submit a new Purchase Order, please create a new Quote Version.
                </Alert>}
            <Page.Body>
                <Content.Section>
                    <Content.Body p={16}>
                        <Grid>
                            <Grid.Col sm={3}>
                                <Text color="dimmed" size="xs">Total Cost to Customer</Text>
                                <Text>{currency(quoteVersion.data.attributes.totalPublicListCostPrice).format()}</Text>
                            </Grid.Col>
                            <Grid.Col sm={3}>
                                <Text color="dimmed" size="xs">Total Cost for Mark III</Text>
                                <Text>{currency(quoteVersion.data.attributes.totalWholesalePrice).format()}</Text>
                            </Grid.Col>
                            <Grid.Col sm={3}>
                                <Text color="dimmed" size="xs">Total Profit</Text>
                                <Text>{currency((quoteVersion.data.attributes.totalPublicListCostPrice - quoteVersion.data.attributes.totalWholesalePrice)).format()}</Text>
                            </Grid.Col>
                            <Grid.Col sm={3}>
                                <Text color="dimmed" size="xs">Average Margin</Text>
                                <Text>{quoteVersion.data.attributes.averageMargin.toFixed(2)}%</Text>
                            </Grid.Col>
                        </Grid>
                    </Content.Body>
                </Content.Section>
                <Content.Section>
                    <Content.Header>
                        <Content.Title>
                            Part List
                        </Content.Title>
                        {!quoteVersion.data.attributes.purchaseOrder?.data &&
                            <EditToggle
                                onSubmitEdit={onSubmitChanges}
                                loading={submitting}
                                showEdit={showEdit}
                                onToggleEdit={() => setShowEdit(!showEdit)}/>}
                    </Content.Header>
                    {showEdit && <Content.Body withDivider p={16}>
                        <Dropzone
                            mb={24}
                            onDrop={onDrop}
                            onReject={(files) => console.log('rejected files', files)}
                            multiple={false}
                            maxSize={3 * 1024 ** 2}
                            accept={[MIME_TYPES.csv]}
                        >
                            <Group position="center" spacing="xl" style={{minHeight: 100, pointerEvents: 'none'}}>
                                <Dropzone.Accept>
                                    <Upload
                                        size={50}
                                        strokeWidth={1.5}
                                        color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                    />
                                </Dropzone.Accept>
                                <Dropzone.Reject>
                                    <X
                                        size={25}
                                        strokeWidth={1.5}
                                        color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                    />
                                </Dropzone.Reject>
                                <Dropzone.Idle>
                                    <FileSpreadsheet size={25} strokeWidth={1.5}/>
                                </Dropzone.Idle>
                                <div>
                                    <Text size="sm" inline>
                                        Upload a filled Mark III Line Item template. Drag CSV files here or click to
                                        select files
                                    </Text>
                                </div>
                            </Group>
                        </Dropzone>
                        <LineItemsMultiList form={lineItemsForm}/>
                    </Content.Body>}
                    {!showEdit &&
                        <LineItemsListTable
                            data={lineItems?.data}
                            loading={isLoadingLineItems}/>}
                </Content.Section>
            </Page.Body>
        </DefaultPageWrapper>
    )

}
