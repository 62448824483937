import {ListItemsTableSettings} from "components/common";
import {ActionIcon, Anchor, Group, Text} from "@mantine/core";
import {RoutesResolver} from "routes";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {GLOBAL_DATE_FORMAT} from "settings";
import {IInvoice} from "features/invoices/types";
import {CircleCheck, CircleX} from "tabler-icons-react";
import {NumberUtils} from "utils";

interface TableSettingProps {
    routesResolver: RoutesResolver,
}

export type TTableSettings =
    (props: TableSettingProps) =>
        ListItemsTableSettings<IInvoice & { id: number }, void>[];

export const TableSettings: TTableSettings = (
    {
        routesResolver,
    }: TableSettingProps) => [
    {
        key: 'invoiceDate',
        label: "Invoice Date",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {invoiceDate} = data;
                return <Text>{dayjs(invoiceDate).format(GLOBAL_DATE_FORMAT)}</Text>
            },
        }
    },
    {
        key: 'filled',
        label: "Filled",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {filled} = data;
                if (filled) {
                    return (
                        <ActionIcon color="green">
                            <CircleCheck size={18}/>
                        </ActionIcon>
                    )
                } else {
                    return (
                        <ActionIcon color="red">
                            <CircleX size={18}/>
                        </ActionIcon>
                    )
                }
            },
        }
    },
    {
        key: 'amount',
        label: "Amount",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {amount} = data;
                return (
                    <Text>
                        ${NumberUtils.numberWithCommas(amount?.toString())}
                    </Text>
                )
            },
        }
    },
    {
        key: 'netTerms',
        label: "Net Terms",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {netTerms} = data;
                return <Text>{netTerms}</Text>
            },
        }
    },
    {
        key: 'calculatedNetTermsDate',
        label: "Net Terms Date",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {calculatedNetTermsDate} = data;
                return <Text>{dayjs(calculatedNetTermsDate).format(GLOBAL_DATE_FORMAT)}</Text>
            },
        }
    },
    {
        key: 'quote',
        label: "Opportunity ID",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {quote} = data;
                if (quote?.data) {
                    return (
                        <Anchor to={routesResolver.getQuoteById(quote.data.id)}
                                component={Link}>
                            {quote.data.attributes.internalQuoteId}
                        </Anchor>
                    )
                } else {
                    return null
                }
            },
        }
    },
]