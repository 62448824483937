import {ModelRoutes, ModelApiService} from "services/strapi";
import { ISupplier } from "features/suppliers";
import {strapi} from "lib/strapi-api";
import axios from "axios";

class SupplierApiSingleton extends ModelApiService<ISupplier> {

}

export const supplierApi = new SupplierApiSingleton(ModelRoutes.Suppliers)


