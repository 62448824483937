import {ModelRoutes, ModelApiService} from "services/strapi";
import { IShipment } from "features/shipments";
import {strapi} from "lib/strapi-api";
import axios from "axios";

class ShipmentApiSingleton extends ModelApiService<IShipment> {

}

export const shipmentApi = new ShipmentApiSingleton(ModelRoutes.Shipments)


