import {ModelHooks} from "services/strapi";
import { invoiceApi } from "../services/invoice.strapi.api";
import { IInvoice } from "../types/invoice.types";

class InvoiceHooks extends ModelHooks<IInvoice> {
    /** Add more functions or properties here that are not described in the Base Strapi
     *  Model Hooks Service.
     **/

}

export const invoiceHooks = new InvoiceHooks(invoiceApi);
