import {Button, PasswordInput, Text, Center, Box, Progress, Group} from "@mantine/core";
import {useForm} from "@mantine/form";
import {useState} from "react";
import { IconCheck, IconX } from '@tabler/icons';

interface Props {
    onResetPassword: (password: string) => void,
}

function PasswordRequirement({ meets, label }: { meets: boolean; label: string }) {
    return (
      <Text color={meets ? 'teal' : 'red'} mt={5} size="sm">
        <Center inline>
          {meets ? <IconCheck size={14} stroke={1.5} /> : <IconX size={14} stroke={1.5} />}
          <Box ml={7}>{label}</Box>
        </Center>
      </Text>
    );
  }

const requirements = [
    { re: /[0-9]/, label: 'Includes number' },
    { re: /[a-z]/, label: 'Includes lowercase letter' },
    { re: /[A-Z]/, label: 'Includes uppercase letter' },
    { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol' },
];

function getStrength(password: string) {
    let multiplier = password.length > 7 ? 0 : 1;

    requirements.forEach((requirement) => {
        if (!requirement.re.test(password)) {
        multiplier += 1;
        }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
}

export function UserResetPasswordForm({onResetPassword}: Props) {
    const [resettingPassword, setResettingPassword] = useState(false);
    const [passwordValue, setPasswordValue] = useState('');
    const strength = getStrength(passwordValue);

    const form = useForm({
        initialValues: {
            password: "",
            confirmPassword: "",
        },

        validate: {
            password: (value) => /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[$&+,:;=?@#|'<>.^*()%!-])(?=\S+$).{8,}$/.test(value) ? null : 'Password should be at least 8 chracters and contain 1 number, 1 upper-case letter, 1 lower-case letter, and 1 special character',
            confirmPassword: (val, values) => values?.password && val === values.password ? null : "Passwords don't match. Try again",
        },
    });

    const checks = requirements.map((requirement, index) => (
        <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(passwordValue)} />
        ));
        const bars = Array(4)
        .fill(0)
        .map((_, index) => (
            <Progress
            styles={{
                bar: { transitionDuration: '0ms' }
            }}
            value={
                passwordValue.length > 0 && index === 0 ? 100 : strength >= ((index + 1) / 4) * 100 ? 100 : 0
            }
            color={strength > 80 ? 'teal' : strength > 50 ? 'yellow' : 'red'}
            key={index}
            size={4}
            />
        ));

    async function handleSubmit(values: any) {
        setResettingPassword(true);
        await onResetPassword(values.password);
        setResettingPassword(false);
    }

    const onPasswordTextChange = (value: any) => {
        setPasswordValue(value.currentTarget.value);
        form.setFieldValue('password', value.currentTarget.value);
    }

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <PasswordInput
                value={passwordValue}
                onChange={onPasswordTextChange}
                placeholder="Your password"
                label="Password"
                required
                mt="md"/>
            <Group spacing={5} grow mt="xs" mb="md">
                {bars}
            </Group>
            <PasswordRequirement label="Has at least 8 characters" meets={passwordValue.length > 7} />
            {checks}
            <PasswordInput label="Confirm Password" placeholder="Confirm Password" required mt="md"
                           {...form.getInputProps("confirmPassword")}/>
            <Button type="submit" fullWidth mt="xl" loading={resettingPassword}>
                Reset Password
            </Button>
        </form>
    )

}
