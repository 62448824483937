import {createStyles, Title, Text, Button, Container, Group} from '@mantine/core';
import {useNavigate} from "react-router-dom";

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: 80,
        paddingBottom: 80,
    },

    label: {
        textAlign: 'center',
        fontWeight: 900,
        fontSize: 100,
        lineHeight: 1,
        marginBottom: theme.spacing.xl * 1.5,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],

        [theme.fn.smallerThan('sm')]: {
            fontSize: 120,
        },
    },

    title: {
        textAlign: 'center',
        fontWeight: 900,
        fontSize: 38,
        color: theme.colorScheme === "dark" ? "white" : theme.colors.dark[6],
        [theme.fn.smallerThan('sm')]: {
            fontSize: 32,
        },
    },

    description: {
        maxWidth: 600,
        margin: 'auto',
        marginTop: theme.spacing.xl,
        marginBottom: theme.spacing.xl * 1.5,
    },
}));

interface Props {
    label?:string,
    title: string
    description: string,
    urlRedirect?: string,
}

export function FeedbackGeneral({label, title, description, urlRedirect}: Props) {
    const {classes} = useStyles();
    const navigate = useNavigate();

    return (
        <Container className={classes.root}>
            {label && <div className={classes.label}>{label}</div>}
            <Title className={classes.title}>{title}</Title>
            <Text color="dimmed" size="lg" align="center" className={classes.description}>
                {description}
            </Text>
            {urlRedirect &&
            <Group position="center">
                <Button size="md" onClick={() => navigate(urlRedirect)}>
                    Back To Home Page
                </Button>
            </Group>}
        </Container>
    );
}
