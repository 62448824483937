import {Button, Text} from "@mantine/core";
import { QuoteCreateForm } from "./quote.create.form";
import { useModals } from "@mantine/modals";
import { Plus } from "tabler-icons-react";
import {IProject} from "features/projects";
import {IStrapiEntity} from "services/strapi";

interface Props {
    onSubmitForm:(values:any) => void,
    initialValues?:any,
    project?:IStrapiEntity<IProject>
}

export function QuoteCreateButton({ onSubmitForm, initialValues, project }:Props) {
    const modals = useModals();

    async function _onSubmitForm(values:any){
        await onSubmitForm(values);
        modals.closeAll();
    }

    const renderQuoteForm = () => {
        modals.openModal({
            withCloseButton:true,
            closeOnClickOutside:true,
            closeOnEscape:true,
            size:900,
            overflow:'outside',
            title: (
                <Text size={'xl'}>Create New Quote</Text>
            ),
            children: (
                <QuoteCreateForm onSubmitQuery={_onSubmitForm} 
                                 project={project} 
                                 initialValues={initialValues}/>
            ),
        })
        return false;
    }

    return (
        <Button leftIcon={<Plus size={18}/>} onClick={renderQuoteForm}>
            New Quote
        </Button>
    )
}
