import {ListItemsTableSettings} from "components/common";
import {Anchor, Text} from "@mantine/core";
import {RoutesResolver} from "routes";
import {IAccount} from "features/accounts";
import {UserAvatar} from "features/users";
import {Link} from "react-router-dom";

interface TableSettingProps {
    routesResolver: RoutesResolver,
}

export type TTableSettings =
    (props: TableSettingProps) =>
        ListItemsTableSettings<IAccount & { id: number }, void>[];

export const TableSettings: TTableSettings = (
    {
        routesResolver,
    }: TableSettingProps) => [
    {
        key: 'name',
        label: "Name",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {name, id} = data;
                return (
                    <Anchor component={Link} to={routesResolver.getAccountById(id)}>
                        {name}
                    </Anchor>
                )
            },
        }
    },
    {
        key: 'salesRep',
        label: "Account Executive",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {salesRep} = data;
                return <UserAvatar user={salesRep?.data.attributes}/>
            },
        }
    },
]