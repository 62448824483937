import {getHeightForScroll} from "settings";
import {Navbar, useMantineTheme, Text} from "@mantine/core";
import {MainLinks} from "components/common";
import {
    LayoutDashboard,
    Package,
    TruckDelivery,
    Users,
    Affiliate,
    ListNumbers,
    ShoppingCart, FileDollar, Crane, ClipboardList, TransferOut, BuildingWarehouse, Atom
} from "tabler-icons-react";
import {RoutesResolver} from "routes";

export function AppNavbar() {
    const theme = useMantineTheme();
    const routesResolver = new RoutesResolver()
    const organizationLinks: any[] = [
        {
            icon: <BuildingWarehouse size={16} color={theme.colors.green[6]}/>,
            color: 'red',
            label: 'Suppliers',
            link: routesResolver.suppliers,
            pageName: 'suppliers',
        },
        {
            icon: <TruckDelivery size={16} color={theme.colors.green[6]}/>,
            color: 'red',
            label: 'Freight Carriers',
            link: routesResolver.freightCarriers,
            pageName: 'freight-carriers'
        },
    ]
    const topLinks: any[] = [
        {
            icon: <Atom size={16} color={theme.colors.green[6]}/>,
            color: 'red',
            label: 'Projects',
            link: routesResolver.projects,
            pageName: 'projects'
        },
    ]

    const purchasesLinks: any[] = [

        {
            icon: <ListNumbers size={16} color={theme.colors.green[6]}/>,
            color: 'red',
            label: 'Quotes',
            link: routesResolver.quotes,
            pageName: 'quotes'
        },
        {
            icon: <ShoppingCart size={16} color={theme.colors.green[6]}/>,
            color: 'red',
            label: 'Purchase Orders',
            link: routesResolver.purchaseOrders,
            pageName: 'purchase-orders'
        },
        {
            icon: <FileDollar size={16} color={theme.colors.green[6]}/>,
            color: 'red',
            label: 'Invoices',
            link: routesResolver.invoices,
            pageName: 'invoices'
        },
        // {
        //     icon: <Crane size={16} color={theme.colors.green[6]}/>,
        //     color: 'red',
        //     label: 'Parts',
        //     link: routesResolver.parts,
        //     pageName: 'parts'
        // },
        // {
        //     icon: <ClipboardList size={16} color={theme.colors.green[6]}/>,
        //     color: 'red',
        //     label: 'Line Items',
        //     link: routesResolver.lineItems,
        //     pageName: 'line-items'
        // },
        {
            icon: <Package size={16} color={theme.colors.green[6]}/>,
            color: 'red',
            label: 'Shipments',
            link: routesResolver.shipments,
            pageName: 'shipments'
        },
    ]

    const links: any[] = [
        {
            icon: <Affiliate size={16} color={theme.colors.green[6]}/>,
            color: 'red',
            label: 'Accounts',
            link: routesResolver.accounts,
            pageName: 'accounts'
        },
        {
            icon: <Users size={16} color={theme.colors.green[6]}/>,
            color: 'red',
            label: 'Users',
            link: routesResolver.users,
            pageName: 'users'
        },
        /* Generated Navbar Links */
    ];
    return (
        <Navbar zIndex={1} style={{
            left: 'initial',
            height: getHeightForScroll()
        }} p="md" width={{xs: 200}}>
            <Navbar.Section mb={12}>
                <MainLinks linkData={topLinks}/>
            </Navbar.Section>
            <Navbar.Section>
                <Text color="dimmed" size="xs" weight={600} mb={6}>Search</Text>
                <MainLinks linkData={purchasesLinks}/>
            </Navbar.Section>
            <Navbar.Section mt="xs">
                <Text color="dimmed" size="xs" weight={600} mb={6}>Logistics</Text>
                <MainLinks linkData={organizationLinks}/>
            </Navbar.Section>
            <Navbar.Section mt="xs">
                <Text color="dimmed" size="xs" weight={600} mb={6}>Management</Text>
                <MainLinks linkData={links}/>
            </Navbar.Section>
        </Navbar>
    )

}
