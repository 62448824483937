import {
    accountHooks,
    AccountCreate,
    IAccount, AccountsListTable
} from "features/accounts";

import {Content, Page} from "components/common";
import {DefaultPageWrapper} from "layouts";
import useChangeDocumentTitle from "hooks/use-change-document-title";

export function Accounts() {
    const {isLoading: isLoadingAccounts, data: accounts} = accountHooks.useGet({
        populate: "*"
    })
    const {mutateAsync} = accountHooks.useCreate();
    useChangeDocumentTitle(`Accounts`)
    async function onSubmitCreate(values: IAccount) {
        return await mutateAsync(values);
    }

    return (
        <DefaultPageWrapper>
            <Page.Header>
                <Page.Title title="Accounts"/>
                <AccountCreate.Button onSubmitForm={onSubmitCreate}/>
            </Page.Header>
            <Page.Body>
                <Content.Section>
                    <Content.Body>
                        <AccountsListTable
                            data={accounts?.data}
                            loading={isLoadingAccounts}/>
                    </Content.Body>
                </Content.Section>
            </Page.Body>
        </DefaultPageWrapper>
    )

}
