import {Table,} from "@mantine/core";
import {IStrapiEntity} from "services/strapi";
import { IPart } from "features/parts";
import {useState} from "react";

interface Props {
    parts: IStrapiEntity<IPart>[],
    onSort?: (field: keyof IPart, reversed: boolean) => void,
}

export function PartsTable({ parts, onSort }: Props) {
    // const [sortBy, setSortBy] = useState<keyof IPart | null>(null);
    // const [reverseSortDirection, setReverseSortDirection] = useState(false);

    const rows = parts.map((part) => {
        // eslint-disable-next-line
        const {/* partId, description */} = part.attributes;
        return (
            <tr key={ part.id }>

            </tr>
        )
    });

    return (
        <Table>
            <thead>
            <tr>

            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    )
}
