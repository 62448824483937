import {Table,} from "@mantine/core";
import {IStrapiEntity} from "services/strapi";
import { IQuoteVersion } from "features/quote-versions";
import {useState} from "react";

interface Props {
    quoteVersions: IStrapiEntity<IQuoteVersion>[],
    onSort?: (field: keyof IQuoteVersion, reversed: boolean) => void,
}

export function QuoteVersionsTable({ quoteVersions, onSort }: Props) {
    // const [sortBy, setSortBy] = useState<keyof IQuoteVersion | null>(null);
    // const [reverseSortDirection, setReverseSortDirection] = useState(false);

    const rows = quoteVersions.map((quoteVersion) => {
        // eslint-disable-next-line
        const {/* lineItems, quote, versionNumber */} = quoteVersion.attributes;
        return (
            <tr key={ quoteVersion.id }>

            </tr>
        )
    });

    return (
        <Table>
            <thead>
            <tr>

            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    )
}
