import {Stack, Title, Group, ActionIcon, Tooltip} from "@mantine/core";
import {Link, Outlet, useParams} from "react-router-dom";
import { invoiceHooks } from "features/invoices";
import {LoaderDefault, NoData} from "components/common";
import {DefaultPageWrapper} from "layouts";
import {ArrowLeft} from "tabler-icons-react";
import {RoutesResolver} from "routes";

export function Invoice() {
    const { invoiceId } = useParams();
    const {isLoading, data: invoice } = invoiceHooks.useGetById(parseInt(invoiceId!), {

    });

    function renderContent() {
        if (isLoading) {
            return <LoaderDefault text="Loading Invoice"/>
        } else if (!invoice) {
            return <NoData title="No Invoice with specified ID found"/>
        } else {
            return <Outlet/>
        }
    }

    const routesResolver = new RoutesResolver();

    return (
        <Stack spacing={0}>
            <DefaultPageWrapper>
                <Group spacing="sm">
                    <Tooltip label="Back to Invoices" withArrow>
                        <ActionIcon component={Link} size="sm" to={ routesResolver.invoices }>
                            <ArrowLeft size={18}/>
                        </ActionIcon>
                    </Tooltip>
                    <Title>Insert Invoice Title Attribute</Title>
                </Group>
            </DefaultPageWrapper>
            <Stack>
                {renderContent()}
            </Stack>
        </Stack>
    )

}
