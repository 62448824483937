import {Text, Image, SimpleGrid, Group} from '@mantine/core';
import {Dropzone as MDropzone, IMAGE_MIME_TYPE, FileWithPath} from '@mantine/dropzone';

interface Props {
    onChange: (value: FileWithPath[]) => void,
    value: FileWithPath[],
    multiple?: boolean,
    text?: string
}

export function Dropzone({
    onChange,
    value,
    multiple,
    text
}: Props) {

    const previews = value?.map((file, index) => {
        const imageUrl = URL.createObjectURL(file);
        return (
            <Image
                key={index}
                src={imageUrl}
                imageProps={{onLoad: () => URL.revokeObjectURL(imageUrl)}}
            />
        );
    });

    return (
        <>
            <MDropzone accept={IMAGE_MIME_TYPE} onDrop={onChange} multiple={multiple}>
                {value?.length > 0 ?
                    <SimpleGrid
                        cols={4}
                        breakpoints={[{
                            maxWidth: 'sm',
                            cols: 1
                        }]}
                    >
                        {previews}
                    </SimpleGrid>
                    :
                    <Group align="center" position="center">
                        <Text align="center">
                            {text ? text : "Drop images here"}
                        </Text>
                    </Group>}
            </MDropzone>
        </>
    );
}
