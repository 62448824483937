import {IUser} from "features/users";

export function concatName(user?:IUser){
    if (user){
        return `${user.firstName} ${user.lastName}`
    } else {
        return ""
    }
}

export function getInitials(user?:IUser){
    if (user && user.firstName && user.lastName){
        return `${user.firstName.at(0)}${user.lastName.at(0)}`
    } else {
        return ""
    }
}
