import {useParams} from "react-router-dom";
import {purchaseOrderHooks} from "features/purchase-orders";
import {Content, EditToggle, Page} from "components/common";
import {DefaultPageWrapper} from "layouts";
import {RoutesResolver} from "routes";
import {LineItemsMultiList} from "features/line-items/components/line-items.multi.list";
import {useForm} from "@mantine/form";
import {Button, Group, Text, useMantineTheme} from "@mantine/core";
import {ILineItemListForm, lineItemHooks, LineItemsListTable} from "features/line-items";
import {Dropzone, FileWithPath, MIME_TYPES} from "@mantine/dropzone";
import {ArrowRight, FileSpreadsheet, Photo, Upload, X} from "tabler-icons-react";
import Papa from 'papaparse'
import {useEffect, useState} from "react";
import {StrapiUtils} from "services/strapi";

export function PurchaseOrder() {
    const {purchaseOrderId} = useParams();
    const theme = useMantineTheme();
    const [submitting, setSubmitting] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const {
        isLoading: isLoadingPurchaseOrder,
        data: purchaseOrder
    } = purchaseOrderHooks.useGetById(parseInt(purchaseOrderId!), {
        populate: "*"
    });
    const {
        isLoading: isLoadingLineItems,
        data: lineItems
    } = lineItemHooks.useGet({
        filters: {
            purchaseOrder: parseInt(purchaseOrderId!)
        },
        populate: "*"
    });
    const routesResolver = new RoutesResolver();
    const lineItemsForm = useForm<{ items: ILineItemListForm[] }>({
        initialValues: {
            items: []
        }
    })
    const {mutateAsync: createLineItem} = lineItemHooks.useCreate()
    const {mutateAsync: updateLineItem} = lineItemHooks.useUpdate()

    async function onDrop(files: FileWithPath[]) {
        const file = files[0]
        const transformHeaders: Record<string, any> = {
            "Item": {
                key: "partName"
            },
            "Item Quantity": {
                key: "quantity",
                shouldParseFloat: true,
            },
            "Item Unit Price": {
                key: "wholesaleCost",
                shouldParseFloat: true,
            },
        }

        function transformToObject(row: Record<string, any>) {
            const object: any = {}
            Object.keys(row).forEach(rowKey => {
                if (transformHeaders[rowKey]) {
                    const {key, shouldParseFloat} = transformHeaders[rowKey]
                    if (shouldParseFloat) {
                        object[key] = parseFloat(row[rowKey])
                    } else {
                        object[key] = row[rowKey]
                    }
                }
            })
            return object
        }

        try {
            Papa.parse<string[]>(file, {
                worker: true,
                skipEmptyLines: true,
                header: true,
                complete({data}) {
                    data.forEach(datum => {
                        const transformed = transformToObject(datum)
                        const {wholesaleCost} = transformed
                        const publicListCost = wholesaleCost + ((10 / 100) * wholesaleCost)
                        lineItemsForm.insertListItem('items', {
                            ...transformToObject(datum),
                            marginPercentage: 10,
                            publicListCost
                        })
                    })
                },
            });
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (lineItems?.data && lineItems.data.length > 0) {
            lineItemsForm.setFieldValue(
                'items',
                StrapiUtils.flattenStrapiEntities(lineItems.data) as ILineItemListForm[]
            )
        }
    }, [lineItems?.data])

    async function onSubmitChanges() {
        setSubmitting(true)
        const {items} = lineItemsForm.values
        if (items.length > 0) {
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.id) {
                    await updateLineItem({
                        id: item.id,
                        values: item
                    })
                } else {
                    await createLineItem({
                        ...item,
                        purchaseOrder: parseInt(purchaseOrderId!)
                    })
                }
            }
        }
        setShowEdit(false)
        setSubmitting(false)
    }

    return (
        <DefaultPageWrapper>
            <Page.Header>
                <Page.Title
                    loading={isLoadingPurchaseOrder}
                    title={`Purchase Order for ${purchaseOrder?.data.attributes.quote?.data?.attributes.internalQuoteId}`}
                    link={{
                        label: "Back to Purchase Orders",
                        to: routesResolver.purchaseOrders
                    }}/>
            </Page.Header>
            <Page.Body>
                <Content.Section>
                    <Content.Header>
                        <Content.Title>
                            Part List
                        </Content.Title>
                        <EditToggle
                            onSubmitEdit={onSubmitChanges}
                            loading={submitting}
                            showEdit={showEdit}
                            onToggleEdit={() => setShowEdit(!showEdit)}/>
                    </Content.Header>
                    {showEdit && <Content.Body withDivider p={16}>
                        <Dropzone
                            mb={24}
                            onDrop={onDrop}
                            onReject={(files) => console.log('rejected files', files)}
                            multiple={false}
                            maxSize={3 * 1024 ** 2}
                            accept={[MIME_TYPES.csv]}
                        >
                            <Group position="center" spacing="xl" style={{minHeight: 100, pointerEvents: 'none'}}>
                                <Dropzone.Accept>
                                    <Upload
                                        size={50}
                                        strokeWidth={1.5}
                                        color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                    />
                                </Dropzone.Accept>
                                <Dropzone.Reject>
                                    <X
                                        size={25}
                                        strokeWidth={1.5}
                                        color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                    />
                                </Dropzone.Reject>
                                <Dropzone.Idle>
                                    <FileSpreadsheet size={25} strokeWidth={1.5}/>
                                </Dropzone.Idle>
                                <div>
                                    <Text size="sm" inline>
                                        Upload a filled Mark III Line Item template. Drag CSV files here or click to
                                        select files
                                    </Text>
                                </div>
                            </Group>
                        </Dropzone>
                        <LineItemsMultiList form={lineItemsForm}/>
                    </Content.Body>}
                    {!showEdit &&
                        <LineItemsListTable
                            data={lineItems?.data}
                            loading={isLoadingLineItems}/>}
                </Content.Section>
            </Page.Body>
        </DefaultPageWrapper>
    )

}
