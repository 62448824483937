import {Center, SegmentedControl, SegmentedControlProps} from "@mantine/core";
import {InvoiceNetTerms} from "features/invoices";

export function InvoiceNetTermsControl(props: SegmentedControlProps) {
    const data = Object.keys(InvoiceNetTerms).map(key => {
        const netTerm = (InvoiceNetTerms as any)[key];
        return ({
            value: (InvoiceNetTerms as any)[key].toString(), label: (
                <Center>
                    {netTerm}
                </Center>
            )
        })
    })
    return <SegmentedControl {...props} data={data}/>
}