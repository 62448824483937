import {IStrapiEntity, StrapiUtils} from "services/strapi";
import {ListItemsTable, ListItemsTableProps} from "components/common";
import {RoutesResolver} from "routes";
import {IInvoice, TableSettings} from "features/invoices";

interface Props extends Omit<ListItemsTableProps<
    IInvoice & { id: number },
    void>,
    'settings' | 'data'> {

    data?: IStrapiEntity<IInvoice>[]
}

export function InvoicesListTable({data, ...rest}: Props){
    const routesResolver = new RoutesResolver()
    return (
        <ListItemsTable
            {...rest}
            data={StrapiUtils.flattenStrapiEntities(data)}
            settings={TableSettings({
                routesResolver,
            })}
        />
    )
}