import {Content, Page} from "components/common";
import {DefaultPageWrapper} from "layouts";
import {useParams} from "react-router-dom";
import {QuoteVersionCreate, quoteVersionHooks, QuoteVersionsListTable} from "features/quote-versions";
import useChangeDocumentTitle from "hooks/use-change-document-title";

export function QuoteQuoteVersions() {
    const {quoteId} = useParams();
    const {isLoading: isLoadingQuoteVersions, data: quoteVersions} = quoteVersionHooks.useGet({
        populate: "*",
        filters: {
            quote: parseInt(quoteId!)
        },

    })
    useChangeDocumentTitle(`Versions`)
    const {mutateAsync, isLoading: isCreatingNewVersion} = quoteVersionHooks.useCreate();

    async function onSubmitCreate(values:any) {
        return await mutateAsync({
            quote: quoteId,
            ...values
        });
    }

    return (
        <DefaultPageWrapper>
            <Page.Header>
                <Page.Title title="Quote Versions"/>
                <QuoteVersionCreate.Button onSubmitForm={onSubmitCreate}/>
                {/*<Button loading={isCreatingNewVersion} leftIcon={<Plus size={18}/>} onClick={onSubmitCreate}>*/}
                {/*    New Version*/}
                {/*</Button>*/}
            </Page.Header>
            <Page.Body>
                <Content.Section>
                    <Content.Body>
                        <QuoteVersionsListTable
                            disableFields={['quote']}
                            loading={isLoadingQuoteVersions}
                            data={quoteVersions?.data}/>
                    </Content.Body>
                </Content.Section>
            </Page.Body>
        </DefaultPageWrapper>
    )
}