import {ListItemsTableSettings} from "components/common";
import {Text} from "@mantine/core";
import {RoutesResolver} from "routes";
import dayjs from "dayjs";
import {GLOBAL_DATE_FORMAT} from "settings";
import {IShipment} from "features/shipments";

interface TableSettingProps {
    routesResolver: RoutesResolver,
}

export type TTableSettings =
    (props: TableSettingProps) =>
        ListItemsTableSettings<IShipment & { id: number }, void>[];

export const TableSettings: TTableSettings = (
    {
        routesResolver,
    }: TableSettingProps) => [
    {
        key: 'trackingNumber',
        label: "Tracking",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {trackingNumber} = data;
                return (
                    <Text>
                        {trackingNumber}
                    </Text>
                )
            },
        }
    },
    {
        key: 'freightCarrier',
        label: "Freight",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {freightCarrier} = data;
                return (
                    <Text>
                        {freightCarrier?.data?.attributes.name}
                    </Text>
                )
            },
        }
    },
    {
        key: 'deliveryDate',
        label: "Delivery Date",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {deliveryDate} = data;
                return <Text>{dayjs(deliveryDate).format(GLOBAL_DATE_FORMAT)}</Text>
            },
        }
    },
    {
        key: 'signedFor',
        label: "Signed For",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {signedFor} = data;
                return (
                    <Text>
                        {signedFor}
                    </Text>
                )
            },
        }
    },
]