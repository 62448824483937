import {Link, useParams} from "react-router-dom";
import {projectHooks} from "features/projects";
import {Content, ExternalIdDisplay, Page} from "components/common";
import {DefaultPageWrapper} from "layouts";
import {RoutesResolver} from "routes";
import {IQuote, QuoteCreate, quoteHooks, QuotesListTable} from "features/quotes";
import {Anchor, Divider, Grid, Group, Stack, Text, useMantineTheme} from "@mantine/core";
import useChangeDocumentTitle from "hooks/use-change-document-title";

export function Project() {
    const {projectId} = useParams();
    const theme = useMantineTheme()
    const {
        isLoading: isLoadingProject,
        data: project
    } = projectHooks.useGetById(parseInt(projectId!), {
        populate: "*"
    });
    useChangeDocumentTitle(project?.data.attributes.name)
    const {
        isLoading: isLoadingQuotes,
        data: quotes
    } = quoteHooks.useGet({
        filters: {
            project: projectId
        },
        populate: "*",
    });
    const {mutateAsync} = quoteHooks.useCreate();

    async function onSubmitCreate(values: IQuote) {
        return await mutateAsync(values);
    }

    const routesResolver = new RoutesResolver();

    return (
        <Stack spacing={0}>
            <Page.Header mb={0} px={24} py={16} bg="white">
                <Group position="apart">
                    <Page.Title
                        loading={isLoadingProject}
                        link={{
                            to: routesResolver.projects,
                            label: "Back to Projects"
                        }}
                        title={project?.data.attributes.name}/>
                </Group>
                <Group spacing="xl">
                    {project?.data.attributes.account?.data &&
                        <Anchor component={Link}
                                to={routesResolver.getAccountById(project.data.attributes.account.data.id)}
                                size="sm">
                            {project?.data.attributes.account.data.attributes.name}
                        </Anchor>}
                    <ExternalIdDisplay externalId={project?.data.attributes.externalId}/>
                </Group>
            </Page.Header>
            <Divider color={theme.colors.gray[3]}/>
            <DefaultPageWrapper>
                <Page.Body>
                    <Content.Section>
                        <Content.Header>
                            <Content.Title>
                                Quotes
                            </Content.Title>
                            <QuoteCreate.Button
                                onSubmitForm={onSubmitCreate}
                                project={project?.data}/>
                        </Content.Header>
                        <Content.Body>
                            <QuotesListTable
                                disableFields={['project', 'account', 'type']}
                                loading={isLoadingQuotes}
                                data={quotes?.data}/>
                        </Content.Body>
                    </Content.Section>
                </Page.Body>
            </DefaultPageWrapper>
        </Stack>
    )
}
