import {useState} from 'react';
import {Box, Button, Grid, Group} from '@mantine/core';
import {useForm} from '@mantine/form';
import {ArrowRight} from "tabler-icons-react";
import { ILineItem } from "features/line-items"

interface Props {
    onSubmitQuery?: (query: any) => void,
    initialValues?: any
}

export function LineItemCreateForm({onSubmitQuery, initialValues}: Props) {
    const [creatingLineItem, setCreatingLineItem] = useState(false);
    const form = useForm<ILineItem>({
        initialValues: initialValues || {},
        validate: {},
    });


    async function onSubmit(values: typeof form.values) {
        if (onSubmitQuery) {
            setCreatingLineItem(true);
            await onSubmitQuery(values)
            setCreatingLineItem(false);
        }
    }


    return (
        <Box mx="auto">
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Grid>
                    <Grid.Col sm={12}>
                        <Group position="right" mt="md">
                            <Button type="submit" loading={ creatingLineItem }
                                    rightIcon={<ArrowRight/>}>
                                Add New Line-Item
                            </Button>
                        </Group>
                    </Grid.Col>
                </Grid>
            </form>
        </Box>
    )
}
