import {ModelRoutes, ModelApiService} from "services/strapi";
import { IQuoteVersion } from "features/quote-versions";
import {strapi} from "lib/strapi-api";
import axios from "axios";

class QuoteVersionApiSingleton extends ModelApiService<IQuoteVersion> {

}

export const quoteVersionApi = new QuoteVersionApiSingleton(ModelRoutes.QuoteVersions)


