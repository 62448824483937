import {
    ActionIcon,
    Group,
    GroupProps,
    Paper,
    PaperProps, Skeleton,
    Stack,
    StackProps,
    Title as MantineTitle,
    TitleProps,
    Tooltip
} from "@mantine/core";
import {Link} from "react-router-dom";
import {ArrowLeft} from "tabler-icons-react";
import React from "react";

function Section({children, ...rest}: PaperProps) {
    return (
        <Paper {...rest}>
            {children}
        </Paper>
    )
}

function Header({children, ...rest}: GroupProps) {
    return (
        <Group mb={24} position="apart" {...rest}>
            {children}
        </Group>
    )
}

function Body({children, ...rest}: StackProps) {
    return (
        <Stack spacing={36} {...rest}>
            {children}
        </Stack>
    )
}

interface ITitleProps extends TitleProps {
    link?: {
        label: string,
        to: string,
    },
    title?: string,
    loading?: boolean
}

function Title({title, link, loading, ...rest}: ITitleProps) {
    return (
        <Group spacing="sm">
            {link &&
                <Tooltip label={link.label} withArrow>
                    <ActionIcon component={Link} size="sm" to={link.to}>
                        <ArrowLeft size={18}/>
                    </ActionIcon>
                </Tooltip>
            }
            {loading ?
                <Skeleton height={30} width={150}/> :
                <MantineTitle order={3} {...rest}>
                    {title}
                </MantineTitle>
            }
        </Group>
    )
}

export const Page = {
    Header, Body, Title
}