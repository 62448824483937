import {Button, Text} from "@mantine/core";
import { ShipmentCreateForm } from "./shipment.create.form";
import { useModals } from "@mantine/modals";
import { Plus } from "tabler-icons-react";

interface Props {
    onSubmitForm:(values:any) => void,
}

export function ShipmentCreateButton({ onSubmitForm }:Props) {
    const modals = useModals();

    async function _onSubmitForm(values:any){
        await onSubmitForm(values);
        modals.closeAll();
    }

    const renderShipmentForm = () => {
        modals.openModal({
            withCloseButton:true,
            closeOnClickOutside:true,
            closeOnEscape:true,
            size:700,
            overflow:'outside',
            title: (
                <Text size={'xl'}>Create New Shipment</Text>
            ),
            children: (
                <ShipmentCreateForm onSubmitQuery={_onSubmitForm}/>
            ),
        })
        return false;
    }

    return (
        <Button leftIcon={<Plus size={18}/>} onClick={renderShipmentForm}>
            New Shipment
        </Button>
    )
}
