import {
    freightCarrierHooks,
    FreightCarrierCreate,
    IFreightCarrier, FreightCarriersListTable
} from "features/freight-carriers";
import {Content, Page} from "components/common";
import {DefaultPageWrapper} from "layouts";
import useChangeDocumentTitle from "hooks/use-change-document-title";

export function FreightCarriers() {
    const {isLoading:isLoadingFreightCarriers, data: freightCarriers } = freightCarrierHooks.useGet({

    })
    const {mutateAsync} = freightCarrierHooks.useCreate();
    useChangeDocumentTitle(`Freight Carriers`)
    async function onSubmitCreate(values: IFreightCarrier) {
        return await mutateAsync(values);
    }

    return (
        <DefaultPageWrapper>
            <Page.Header>
                <Page.Title title="Freight Carriers"/>
                <FreightCarrierCreate.Button onSubmitForm={onSubmitCreate}/>
            </Page.Header>
            <Page.Body>
                <Content.Section>
                    <Content.Body>
                        <FreightCarriersListTable
                            data={freightCarriers?.data}
                            loading={isLoadingFreightCarriers}/>
                    </Content.Body>
                </Content.Section>
            </Page.Body>
        </DefaultPageWrapper>
    )

}
