import {ModelRoutes, ModelApiService} from "services/strapi";
import { IProject } from "features/projects";
import {strapi} from "lib/strapi-api";
import axios from "axios";

class ProjectApiSingleton extends ModelApiService<IProject> {

}

export const projectApi = new ProjectApiSingleton(ModelRoutes.Projects)


