import {ModelRoutes, ModelApiService} from "services/strapi";
import { IPurchaseOrder } from "features/purchase-orders";
import {strapi} from "lib/strapi-api";
import axios from "axios";

class PurchaseOrderApiSingleton extends ModelApiService<IPurchaseOrder> {

}

export const purchaseOrderApi = new PurchaseOrderApiSingleton(ModelRoutes.PurchaseOrders)


