import {Anchor, Group, Stack, Text} from "@mantine/core";
import {Link, useNavigate, useParams} from "react-router-dom";
import {quoteHooks} from "features/quotes";
import {ExternalIdDisplay, Page, Tabs} from "components/common";
import {DefaultPageWrapper} from "layouts";
import {
    FileDollar,
    Files,
    GitBranch,
    Settings,
    ShoppingCart,
    TruckDelivery,
} from "tabler-icons-react";
import {RouteSegments, RoutesResolver} from "routes";
import {useEffect} from "react";
import useChangeDocumentTitle from "hooks/use-change-document-title";

export function Quote() {
    const {quoteId} = useParams();
    const navigate = useNavigate();
    const {isLoading, data: quote} = quoteHooks.useGetById(parseInt(quoteId!), {
        populate: "*"
    });
    useChangeDocumentTitle(quote?.data.attributes.name)

    useEffect(() => {
        const split = location.pathname.split("/").filter(el => el);
        if (split.length === 2) {
            navigate(RouteSegments.QuoteVersions, {replace: true});
        }
    }, [quoteId])

    function onTabChange(value: string) {
        navigate(value);
    }

    const links = [
        {
            label: "Versions",
            value: RouteSegments.QuoteVersions,
            icon: <GitBranch size={18}/>,
        },
        {
            label: "Purchase Orders",
            value: RouteSegments.PurchaseOrders,
            icon: <ShoppingCart size={18}/>,
        },
        {
            label: "Invoices",
            value: RouteSegments.Invoices,
            icon: <FileDollar size={18}/>,
        },
        {
            label: "Shipments",
            value: RouteSegments.Shipments,
            icon: <TruckDelivery size={18}/>,
        },
        {
            label: "Files",
            value: RouteSegments.Files,
            icon: <Files size={18}/>,
        },
        {
            label: "Settings",
            value: RouteSegments.Settings,
            icon: <Settings size={18}/>,
            disabled: false,
        },
    ]

    function getCurTab() {
        const split = location.pathname.split("/").filter(el => el);
        const curLink = links.find(link => split.indexOf(link.value) !== -1);
        return curLink?.value;
    }

    const routesResolver = new RoutesResolver();

    return (
        <Stack p={0} spacing={0} sx={theme => ({
            backgroundColor: `${theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.white}`,
        })}>
            <DefaultPageWrapper px={24} py={12}>
                <Page.Header mb={0}>
                    <Page.Title loading={isLoading}
                                title={quote?.data?.attributes.name}
                                link={{
                                    label: "Back to Quotes",
                                    to: routesResolver.quotes
                                }}/>
                    <Stack spacing={6}>
                        {quote?.data.attributes.project?.data &&
                            <Group position="apart" spacing="xl">
                                <Text size="sm">Project</Text>
                                <Anchor component={Link}
                                    to={routesResolver.getProjectById(quote.data.attributes.project.data.id)}
                                    size="sm">
                                {quote?.data.attributes.project.data.attributes.name}
                            </Anchor>
                            </Group>}
                        <Group position="apart" spacing="xl">
                            <Text size="sm">Quote ID</Text>
                            <ExternalIdDisplay externalId={quote?.data.attributes.externalId}/>
                        </Group>
                    </Stack>
                </Page.Header>
            </DefaultPageWrapper>
            <Tabs.Wrapper tab={getCurTab()} onTabChange={onTabChange} links={links}/>
            <Tabs.Outlet/>
        </Stack>
    )

}
