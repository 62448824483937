import React from 'react';
import {UnstyledButton, Group, Text, Tooltip, createStyles, Stack} from '@mantine/core';
import {Link, useLocation, useResolvedPath} from "react-router-dom";
import {ThemeManager} from "utils";

interface MainLinkProps {
    icon: React.ReactNode;
    color: string;
    label: string;
    link: string,
    pageName: string,
    disabled?: boolean
}

const useStyles = createStyles(theme => ({
    mainLink: {
        width: 44,
        height: 44,
        borderRadius: theme.radius.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
        },
    },

    mainLinkActive: {
        '&, &:hover': {
            backgroundColor: ThemeManager.chooseColor(theme),
            color: ThemeManager.chooseColor(theme, true),
        },
    },
}))

function MainLink({icon, label, link}: MainLinkProps) {
    const resolved = useResolvedPath(link);
    const location = useLocation();
    const match = location.pathname.startsWith(resolved.pathname);

    function getStyles(theme: any) {
        const curTheme: any = {
            display: 'block',
            padding: 6,
            borderRadius: theme.radius.sm,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
            marginBottom: 6,
            '&:last-of-type': {
                marginBottom: 0,
            },
            '&:hover': {
                backgroundColor:
                    theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            },
        }
        if (match) {
            curTheme.backgroundColor = ThemeManager.chooseColor(theme, false, theme.colors.gray[1], theme.colors.dark[6])
        }
        return curTheme;
    }

    return (
        <UnstyledButton
            component={Link}
            to={link}
            sx={getStyles}
        >
            <Group spacing="xs">
                {icon}
                <Text size="xs">{label}</Text>
            </Group>
        </UnstyledButton>

    );
}

interface Props {
    linkData: MainLinkProps[],
    collapsed?: boolean,
}

export function MainLinks({linkData, collapsed}: Props) {
    const {classes, cx} = useStyles();
    const location = useLocation();
    const links = linkData.filter(link => !link.disabled).map((link) => {
        return <MainLink {...link} key={link.label}/>
    });

    function getCurTab() {
        const curLink = linkData.find(link => location.pathname.indexOf(link.link) !== -1);
        if (!curLink) {
            return "Dashboard"
        } else {
            return curLink.label;
        }
    }

    if (collapsed) {
        return (
            <Stack>
                {linkData.map(curLink => {
                    const {icon, label, link} = curLink;
                    return (
                        <Tooltip label={label} position="right" withArrow transitionDuration={0} key={label}>
                            <UnstyledButton
                                component={Link}
                                to={link}
                                className={cx(classes.mainLink, {[classes.mainLinkActive]: label === getCurTab()})}
                            >
                                {icon}
                            </UnstyledButton>
                        </Tooltip>
                    )
                })}
            </Stack>
        )
    } else {
        return <div>{links}</div>;
    }
}
