import {Select, SelectProps} from "@mantine/core";
import {EInvoiceFilled} from "features/invoices";

export function InvoiceFilledSelect(props: Omit<SelectProps, 'data'>) {
    const data = Object.keys(EInvoiceFilled).map(key => (
        {value: (EInvoiceFilled as any)[key], label: key}
    ))

    return (
        <Select {...props} data={data}/>
    )
}