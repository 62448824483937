import {ListItemsTableSettings} from "components/common";
import {Text, Tooltip} from "@mantine/core";
import {RoutesResolver} from "routes";
import {ILineItem} from "features/line-items/types";
import {NumberUtils} from "utils";
import currency from "currency.js";

interface TableSettingProps {
    routesResolver: RoutesResolver,
}

export type TTableSettings =
    (props: TableSettingProps) =>
        ListItemsTableSettings<ILineItem & { id: number }, void>[];

export const TableSettings: TTableSettings = (
    {
        routesResolver,
    }: TableSettingProps) => [
    {
        key: 'partNumber',
        label: "Part Number",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {partNumber} = data;
                return (
                    <Text>{partNumber}</Text>
                )
            },
        }
    },
    {
        key: 'partDescription',
        label: "Description",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {partDescription} = data;
                return (
                    <Tooltip width={200} multiline label={partDescription}>
                        <Text maw={300} lineClamp={1}>{partDescription}</Text>
                    </Tooltip>
                )
            },
        }
    },
    {
        key: 'quantity',
        label: "Quantity",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {quantity} = data;
                return (
                    <Text>{quantity}</Text>
                )
            },
        }
    },
    {
        key: 'wholesaleCost',
        label: "Supplier Cost",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {wholesaleCost} = data;
                return (
                    <Text>{wholesaleCost ? currency(wholesaleCost).format() : 0}</Text>
                )
            },
        }
    },
    {
        key: 'marginPercentage',
        label: "Margin",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {marginPercentage} = data;
                return (
                    <Text>{marginPercentage?.toFixed(2)}%</Text>
                )
            },
        }
    },
    {
        key: 'publicListCost',
        label: "Cost to Customer",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {publicListCost} = data;
                return (
                    <Text>{publicListCost ? currency(publicListCost).format() : 0}</Text>
                )
            },
        }
    },
]