import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, Group, NumberInput, Stack, Text} from '@mantine/core';
import {useForm} from '@mantine/form';
import {ArrowRight} from "tabler-icons-react";
import {IInvoice, InvoiceFilledSelect, InvoiceNetTermsControl} from "features/invoices"
import {DatePicker} from "@mantine/dates";
import dayjs from 'dayjs';

interface Props {
    onSubmitQuery?: (query: any) => void,
    initialValues?: any
}

export function InvoiceCreateForm({onSubmitQuery, initialValues}: Props) {
    const [creatingInvoice, setCreatingInvoice] = useState(false);
    const form = useForm<IInvoice>({
        initialValues: initialValues || {
            invoiceDate: dayjs().toDate()
        },
        validate: {},
    });

    useEffect(() => {
        if (form.values.netTerms && form.values.netTerms !== 0){
            form.setValues({
                calculatedNetTermsDate: dayjs(form.values.invoiceDate).add(form.values.netTerms, 'days').toDate()
            })
        }
    }, [form.values.netTerms])

    async function onSubmit(values: typeof form.values) {
        if (onSubmitQuery) {
            setCreatingInvoice(true);
            await onSubmitQuery(values)
            setCreatingInvoice(false);
        }
    }


    return (
        <Box mx="auto">
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Grid>
                    <Grid.Col sm={6}>
                        <InvoiceFilledSelect label="Filled" {...form.getInputProps('filled')}/>
                    </Grid.Col>
                    <Grid.Col sm={6}>
                        <DatePicker clearable={false} label="Invoice Date" {...form.getInputProps('invoiceDate')}/>
                    </Grid.Col>
                    <Grid.Col sm={12}>
                        <NumberInput
                            label="Amount"
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                            hideControls
                            formatter={(value) =>
                                value && !Number.isNaN(parseFloat(value))
                                    ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                                    : '$ '
                            }
                            {...form.getInputProps('amount')}/>
                    </Grid.Col>
                    <Grid.Col sm={6}>
                        <Stack spacing={2}>
                            <Text size="sm">Net Terms</Text>
                            <InvoiceNetTermsControl label="Net Terms" {...form.getInputProps('netTerms')}/>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col sm={6}>
                        <DatePicker disabled label="Net Terms Date" {...form.getInputProps('calculatedNetTermsDate')}/>
                    </Grid.Col>
                    <Grid.Col sm={12}>
                        <Group position="right" mt="md">
                            <Button type="submit" loading={creatingInvoice}
                                    rightIcon={<ArrowRight/>}>
                                Add New Invoice
                            </Button>
                        </Group>
                    </Grid.Col>
                </Grid>
            </form>
        </Box>
    )
}
