import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import { userApi } from "../services/user.strapi.api";

interface UpdateUserPayload<ValuesType> {
    id:number,
    values:ValuesType
}

class UserApiHooks {
    useUsers = (query: any) => {
        return useQuery(
            ["fetchUsers", query],
            () => userApi.getUsers(query)
        );
    }

    useGetInvitationRolePermissions = (roleName: string) => {
        return useQuery(
            ["fetchInvitationRolePermissions", roleName],
            () => userApi.getUserRoleInvitationPermissions(roleName)
        );
    }

    useGetUserAvatars = (userIds: number[]) => {
        return useQuery(
            ["fetchUserAvatars", userIds],
            () => userApi.getUserAvatars(userIds)
        );
    }

    useMutateInviteUser = () => {
        const queryClient = useQueryClient();
        return useMutation(userApi.inviteUser, {
            onSuccess: () => {
                queryClient.invalidateQueries(["fetchUsers"])
            },
        })
    }

    useUpdateUser = (query?:any) => {
        const queryClient = useQueryClient();
        return useMutation(
            (payload:UpdateUserPayload<any>) => userApi.updateUser(
                payload.id,
                payload.values, query
            ),
            {onSuccess: () => queryClient.invalidateQueries(["fetchUsers"])}
        );
    }
}

export const userHooks = new UserApiHooks();
