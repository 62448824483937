import {Anchor, Button, Checkbox, Group, PasswordInput, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {ILoginValues} from "services/strapi";
import {useState} from "react";
import {ArrowRight} from "tabler-icons-react";
import {Link} from "react-router-dom";
import {RoutesResolver} from "routes";

interface Props {
    initialValues?:ILoginValues,
    onSubmit: (values:ILoginValues) => void,
}

export function LoginForm({ initialValues, onSubmit }:Props) {
    const form = useForm<ILoginValues>({initialValues})
    const [loggingIn, setLoggingIn] = useState(false);

    async function handleSubmit(values:ILoginValues){
        setLoggingIn(true);
        await onSubmit(values);
        setLoggingIn(false);
    }

    const routesResolver = new RoutesResolver();

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <TextInput label="Email" placeholder="you@yourcompany.com" required
                       {...form.getInputProps("identifier")}/>
            <PasswordInput label="Password" placeholder="Your password" required mt="md"
                           {...form.getInputProps("password")}/>
            <Group align="center" position="apart" mt="md">
                <Checkbox sx={{
                    display: "flex"
                }} label="Remember me"
                          {...form.getInputProps("remember", {type: 'checkbox'})}/>
                <Anchor component={Link} to={routesResolver.forgotPassword} size="sm">
                    Forgot password?
                </Anchor>
            </Group>
            <Button rightIcon={<ArrowRight size={18}/>} type="submit" fullWidth mt="xl" loading={loggingIn}>
                Sign in
            </Button>
        </form>
    )
}
