import {ModelHooks} from "services/strapi";
import { partApi } from "../services/part.strapi.api";
import { IPart } from "../types/part.types";

class PartHooks extends ModelHooks<IPart> {
    /** Add more functions or properties here that are not described in the Base Strapi
     *  Model Hooks Service.
     **/

}

export const partHooks = new PartHooks(partApi);
