import {Stack, Title, Group, ActionIcon, Tooltip} from "@mantine/core";
import {Link, Outlet, useParams} from "react-router-dom";
import { freightCarrierHooks } from "features/freight-carriers";
import {LoaderDefault, NoData} from "components/common";
import {DefaultPageWrapper} from "layouts";
import {ArrowLeft} from "tabler-icons-react";
import {RoutesResolver} from "routes";

export function FreightCarrier() {
    const { freightCarrierId } = useParams();
    const {isLoading, data: freightCarrier } = freightCarrierHooks.useGetById(parseInt(freightCarrierId!), {

    });

    function renderContent() {
        if (isLoading) {
            return <LoaderDefault text="Loading Freight-Carrier"/>
        } else if (!freightCarrier) {
            return <NoData title="No Freight-Carrier with specified ID found"/>
        } else {
            return <Outlet/>
        }
    }

    const routesResolver = new RoutesResolver();

    return (
        <Stack spacing={0}>
            <DefaultPageWrapper>
                <Group spacing="sm">
                    <Tooltip label="Back to Freight-Carriers" withArrow>
                        <ActionIcon component={Link} size="sm" to={ routesResolver.freightCarriers }>
                            <ArrowLeft size={18}/>
                        </ActionIcon>
                    </Tooltip>
                    <Title>Insert Freight-Carrier Title Attribute</Title>
                </Group>
            </DefaultPageWrapper>
            <Stack>
                {renderContent()}
            </Stack>
        </Stack>
    )

}
