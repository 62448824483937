import {CONTENT_ALIGN, DEFAULT_MAX_CONTENT_WIDTH} from "settings";
import {Stack} from "@mantine/core";
import {Outlet} from "react-router-dom";

export function TabsRender() {
    return (
        <Stack align={CONTENT_ALIGN} sx={theme => ({
            width: "100%",
            backgroundColor: `${theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0]}`
        })}>
            <Stack spacing={0} sx={theme => ({
                maxWidth: DEFAULT_MAX_CONTENT_WIDTH,
                width: "100%",
                backgroundColor: `${theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0]}`
            })}>
                <Outlet/>
            </Stack>
        </Stack>
    )
}