import {ModelHooks} from "services/strapi";
import { quoteApi } from "../services";
import { IQuote } from "../types";

class QuoteHooks extends ModelHooks<IQuote> {
    /** Add more functions or properties here that are not described in the Base Strapi
     *  Model Hooks Service.
     **/

}

export const quoteHooks = new QuoteHooks(quoteApi);
