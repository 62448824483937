import React from 'react';
import Logo from "assets/logo.png";
import {Group, Header, Text, Image, Stack, useMantineTheme} from "@mantine/core";
import {Outlet} from "react-router-dom";
import {UserButton} from "features/users";
import {ThemeManager} from "utils";
import {APP_NAME, HOMEPAGE_URL} from "settings";
import {authHooks} from "features/auth";

export function AppHeader() {
    const theme = useMantineTheme();
    const {data: me} = authHooks.useMe();
    return (
        <Stack spacing={0}>
            <Header height={60} zIndex={1}>
                <Stack spacing={0} sx={{height:"100%"}}>
                    <Group px={20} py={10} sx={{height:"100%"}} position="apart">
                        <Group spacing={9} align="center">
                            <a href={HOMEPAGE_URL}>
                                <Image src={Logo} alt={`${APP_NAME} logo`} height={26}/>
                            </a>
                            <Text
                                size={26}
                                style={{lineHeight: "1em"}}
                                weight={600}
                                color={ThemeManager.chooseColor(theme, true)}>
                                {APP_NAME}
                            </Text>
                        </Group>
                        {me && <UserButton/>}
                    </Group>
                </Stack>
            </Header>
            <Outlet/>
        </Stack>
    );
}
