import {EQuoteStatus, EQuoteType, IQuote, IQuoteForm} from "features/quotes/types";
import {generateExternalId} from "utils";
import moment from "moment/moment";
import {notEmpty} from "@mantine/form";

export class QuoteUtils {
    static transformInitialValues(quote?: IQuote): IQuoteForm {
        if (!quote) {
            return {
                name: "",
                externalId: generateExternalId(),
                internalQuoteId: moment().format("MMDDYY_hhmmss"),
                isStandard: false,
                status: EQuoteStatus.Active,
                type: EQuoteType.Draft,
            }
        } else {
            return {
                ...quote,
                contact: quote.contact?.data?.id.toString(),
                account: quote.account?.data?.id.toString(),
                salesRep: quote.salesRep?.data?.id.toString(),
                insideSalesRep: quote.insideSalesRep?.data?.id.toString(),
                project: quote.project?.data?.id.toString(),
            }
        }
    }

    static getValidationSchema(): Partial<Record<keyof IQuoteForm, any>> {
        return {
            name: notEmpty(),
            externalId: notEmpty(),
            contact: notEmpty(),
            account: notEmpty(),
            salesRep: notEmpty(),
            insideSalesRep: notEmpty(),
            project: notEmpty(),
        }
    }
}
