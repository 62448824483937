import {ModelRoutes, ModelApiService} from "services/strapi";
import { IPart } from "features/parts";
import {strapi} from "lib/strapi-api";
import axios from "axios";

class PartApiSingleton extends ModelApiService<IPart> {

}

export const partApi = new PartApiSingleton(ModelRoutes.Parts)


