import {Box, Button, Center, Code, Group, TextInput, Text, Stack, NumberInput, Grid, ActionIcon} from "@mantine/core";
import {UseFormReturnType} from "@mantine/form";
import {ILineItem} from "features/line-items/types";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {CircleMinus, CurrencyDollar, GripVertical, Plus} from "tabler-icons-react";

interface Props {
    form: UseFormReturnType<{ items: ILineItem[] }>
}

export function LineItemsMultiList(
    {
        form
    }: Props) {

    function onChangeMargin(index: number, marginPercentage?: number) {
        const {wholesaleCost} = form.values.items[index]
        if (marginPercentage !== undefined && wholesaleCost) {
            const newPublicListCost = wholesaleCost + ((marginPercentage / 100) * wholesaleCost)
            form.setFieldValue(`items.${index}.publicListCost`, newPublicListCost)
            form.setFieldValue(`items.${index}.marginPercentage`, marginPercentage)
        }
    }

    function onChangeListCost(index: number, publicListCost?: number) {
        const {wholesaleCost} = form.values.items[index]
        if (publicListCost !== undefined && wholesaleCost) {
            const newMarginPercentage = ((publicListCost - wholesaleCost) / wholesaleCost) * 100
            form.setFieldValue(`items.${index}.marginPercentage`, newMarginPercentage)
            form.setFieldValue(`items.${index}.publicListCost`, publicListCost)
        }
    }

    const fields = form.values.items.map((_, index) => (
        <Draggable key={index} index={index} draggableId={index.toString()}>
            {(provided) => (
                <Grid grow ref={provided.innerRef} mt="xs" {...provided.draggableProps}>
                    <Grid.Col {...provided.dragHandleProps} sx={{
                        display: "flex",
                        alignItems: "center"
                    }} span="content">
                        <GripVertical size="1.2rem"/>
                    </Grid.Col>
                    <Grid.Col sm={2}>
                        <TextInput
                            placeholder="Part Number"
                            {...form.getInputProps(`items.${index}.partNumber`)} />
                    </Grid.Col>
                    <Grid.Col sm={2}>
                        <TextInput
                            placeholder="Part Description"
                            {...form.getInputProps(`items.${index}.partDescription`)} />
                    </Grid.Col>
                    <Grid.Col sm={1}>
                        <NumberInput
                            placeholder="Quantity"
                            {...form.getInputProps(`items.${index}.quantity`)}
                        />
                    </Grid.Col>
                    <Grid.Col sm={2}>
                        <NumberInput
                            precision={2}
                            icon={<Text size="sm">$</Text>}
                            hideControls
                            placeholder="Wholesale Cost"
                            {...form.getInputProps(`items.${index}.wholesaleCost`)}
                        />
                    </Grid.Col>
                    <Grid.Col sm={1}>
                        <NumberInput
                            precision={2}
                            placeholder="Margin %"
                            {...form.getInputProps(`items.${index}.marginPercentage`)}
                            onChange={(value) => onChangeMargin(index, value)}
                        />
                    </Grid.Col>
                    <Grid.Col sm={2}>
                        <NumberInput
                            precision={2}
                            icon={<CurrencyDollar size={18}/>}
                            hideControls
                            placeholder="Public List Cost"
                            {...form.getInputProps(`items.${index}.publicListCost`)}
                            onChange={(value) => onChangeListCost(index, value)}
                        />
                    </Grid.Col>
                    <Grid.Col sx={{
                        display: "flex",
                        alignItems: "center"
                    }} span="content">
                        <ActionIcon color="red" size="xs" onClick={() =>form.removeListItem('items', index)}>
                            <CircleMinus size={18} strokeWidth={1.5}/>
                        </ActionIcon>
                    </Grid.Col>
                </Grid>
            )}
        </Draggable>
    ));

    return (
        <Stack spacing={0}>
            <DragDropContext
                onDragEnd={({destination, source}) =>
                    form.reorderListItem('items', {from: source.index, to: destination!.index})
                }
            >
                <Droppable droppableId="dnd-list" direction="vertical">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            <Grid grow>
                                <Grid.Col span="content">
                                    <GripVertical style={{visibility: "hidden"}} size="1.2rem"/>
                                </Grid.Col>
                                <Grid.Col sm={2}>
                                    <Text size="xs">Part Number</Text>
                                </Grid.Col>
                                <Grid.Col sm={2}>
                                    <Text size="xs">Part Description</Text>
                                </Grid.Col>
                                <Grid.Col sm={1}>
                                    <Text size="xs">Quantity</Text>
                                </Grid.Col>
                                <Grid.Col sm={2}>
                                    <Text size="xs">Supplier Cost</Text>
                                </Grid.Col>
                                <Grid.Col sm={1}>
                                    <Text size="xs">Margin %</Text>
                                </Grid.Col>
                                <Grid.Col sm={2}>
                                    <Text size="xs">Customer Cost</Text>
                                </Grid.Col>
                                <Grid.Col sx={{
                                    visibility: "hidden",
                                    display: "flex",
                                    alignItems: "center"
                                }} span="content">
                                    <ActionIcon color="red" size="xs">
                                        <CircleMinus size={18} strokeWidth={1.5}/>
                                    </ActionIcon>
                                </Grid.Col>
                            </Grid>
                            {fields}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <Group position="left" mt="xl">
                <Button variant="subtle" leftIcon={<Plus size={18}/>} onClick={() => form.insertListItem('items', {
                    partName: '',
                    quantity: 0,
                    wholesaleCost: 0,
                    marginPercentage: 10,
                    publicListCost: 0
                })}>
                    Add Line Item
                </Button>
            </Group>
        </Stack>
    );
}