import {ListItemsTableSettings} from "components/common";
import {Anchor, Group, Text} from "@mantine/core";
import {RoutesResolver} from "routes";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {GLOBAL_DATE_FORMAT} from "settings";
import {IPurchaseOrder} from "features/purchase-orders/types";
import {ArrowRight} from "tabler-icons-react";

interface TableSettingProps {
    routesResolver: RoutesResolver,
}

export type TTableSettings =
    (props: TableSettingProps) =>
        ListItemsTableSettings<IPurchaseOrder & { id: number }, void>[];

export const TableSettings: TTableSettings = (
    {
        routesResolver,
    }: TableSettingProps) => [
    // {
    //     key: 'supplier',
    //     label: "Supplier",
    //     isNotFormValue: true,
    //     Render: {
    //         Static: function ({data}) {
    //             const {supplier, id} = data;
    //             return (
    //                 <Text>{supplier?.data?.attributes.name}</Text>
    //             )
    //         },
    //     }
    // },
    {
        key: 'quoteVersion',
        label: "Quote Version",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {quote, quoteVersion, id} = data;
                if (quote?.data && quoteVersion?.data) {
                    return (
                        <Anchor component={Link} to={
                            routesResolver.getQuoteVersionById(quote.data.id, quoteVersion.data.id)
                        }>{`Version #${quoteVersion.data.attributes.versionNumber}`}</Anchor>
                    )
                } else {
                    return null
                }
            },
        }
    },
    {
        key: 'orderPlacedAt',
        label: "Ordered",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {orderPlacedAt} = data;
                return (
                    <Text>{orderPlacedAt ? dayjs(orderPlacedAt).format(GLOBAL_DATE_FORMAT) : "N/A"}</Text>
                )
            },
        }
    },
    {
        key: 'scheduledShipDate',
        label: "Scheduled Ship Date",
        isNotFormValue: true,
        Render: {
            Static: function ({data}) {
                const {scheduledShipDate} = data;
                return (
                    <Text>{ scheduledShipDate ? dayjs(scheduledShipDate).format(GLOBAL_DATE_FORMAT) : "N/A"}</Text>
                )
            },
        }
    },
    // {
    //     key: 'actions',
    //     label: "",
    //     isNotFormValue: true,
    //     Render: {
    //         Static: function ({data}) {
    //             const {id} = data;
    //             return (
    //                 <Group position="right">
    //                     <Anchor component={Link}
    //                             to={routesResolver.getPurchaseOrderById(id)}>
    //                         <Group spacing={6}>
    //                             <Text>To Purchase Order</Text>
    //                             <ArrowRight size={12}/>
    //                         </Group>
    //                     </Anchor>
    //                 </Group>
    //             )
    //         },
    //     }
    // },
]