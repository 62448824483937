import {IStrapiEntity, IStrapiResponse} from "services/strapi";
import {IQuote} from "features/quotes";

export interface IInvoice {
    filled?: EInvoiceFilled,
    invoiceDate?: any,
    amount?: number,
    netTerms?: number,
    calculatedNetTermsDate?: any,
    quote?: IStrapiResponse<IStrapiEntity<IQuote>>
}

export enum EInvoiceFilled {
    No = "no",
    Yes = "yes",
    Partial = "partial"
}

export const InvoiceNetTerms = {
    Zero: 0,
    Thirty: 30,
    FortyFive: 45,
    Sixty: 60,
    Ninety: 90
}