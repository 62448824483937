import {
    AppShell,
    Group,
    Text,
    useMantineTheme,
    Image,
    Stack,
    Paper,
    Container,
} from "@mantine/core";
import {Outlet} from "react-router-dom";
import React from "react";
import Logo from "assets/logo.png";
import { APP_NAME } from 'settings';

export function Auth() {
    const theme = useMantineTheme();
    return (
        <AppShell
            styles={(theme) => ({
                main: {
                    backgroundColor:
                        theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                },
            })}
        >
            <Stack justify="center" align="center" style={{height: "100%"}}>
                <Container size="xl" style={{position: "relative"}}>
                    <Paper shadow="xs" radius="md" style={{
                        zIndex: 2,
                        position: "relative",
                        overflow: "hidden"
                    }}>
                        <Stack p={30} spacing={0} align="center" justify="center" style={{position: "relative"}}>
                            <Group mb={48} style={{top: 40}}>
                                <a href='https://quotes.markiiidash.com'>
                                    <Image src={Logo} alt='Logo'
                                            height={36}/>
                                </a>
                                <Text
                                    color={theme.colorScheme === "dark" ? "white" : "inherit"}
                                    size={40}
                                    style={{lineHeight: "1em"}} weight={600}>
                                    {APP_NAME}
                                </Text>
                            </Group>
                            <Container>
                                <Outlet/>
                            </Container>
                            <Group position="center" px={20} mt={24}>
                                <Text color="dimmed" weight={600} size="xs">
                                    © {new Date().getFullYear()} {APP_NAME} LLC. All Rights Reserved
                                </Text>
                            </Group>
                        </Stack>
                    </Paper>
                </Container>
            </Stack>
        </AppShell>

    )

}
