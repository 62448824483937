import {
    quoteVersionHooks,
    QuoteVersionCreate,
    IQuoteVersion
} from "features/quote-versions";

import {LoaderDefault, NoData} from "components/common";
import {Group, Title} from "@mantine/core";
import { QuoteVersionsTable } from "features/quote-versions";
import {DefaultPageWrapper} from "layouts";

export function QuoteVersions() {
    const {isLoading, data: quoteVersions } = quoteVersionHooks.useGet({

    })
    const {mutateAsync} = quoteVersionHooks.useCreate();

    async function onSubmitCreate(values: IQuoteVersion) {
        return await mutateAsync(values);
    }

    function renderContent() {
        if (isLoading) {
            return <LoaderDefault text="Loading Quote-Version"/>
        } else if (!quoteVersions?.data || quoteVersions.meta.pagination.total === 0) {
            return <NoData title="No Quote-Versions"/>
        } else {
            return (
                <QuoteVersionsTable quoteVersions={ quoteVersions.data }/>
            )
        }
    }

    return (
        <DefaultPageWrapper>
            <Group position="apart" mb={12}>
                <Title>Quote-Version</Title>
                <QuoteVersionCreate.Button onSubmitForm={onSubmitCreate}/>
            </Group>
            {renderContent()}
        </DefaultPageWrapper>
    )

}
