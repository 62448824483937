import {ModelRoutes, ModelApiService} from "services/strapi";
import { IFreightCarrier } from "features/freight-carriers";
import {strapi} from "lib/strapi-api";
import axios from "axios";

class FreightCarrierApiSingleton extends ModelApiService<IFreightCarrier> {

}

export const freightCarrierApi = new FreightCarrierApiSingleton(ModelRoutes.FreightCarriers)


